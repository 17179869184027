import React, { useEffect, useState } from "react";
//import profilepic from "../../assets/profilepic.svg";
import Faq from "./Faq";
import HeaderComponent from "./HeaderComponent";
import axios from "axios";
import MainLoader from "./MainLoader";
import { useMyContext } from "../../ContextApi";
// import { TailSpin } from 'react-loader-spinner'

const ENDPOINT = "https://partypal-api.onrender.com/api/support";
const token = sessionStorage.getItem("token");

export default function SupportVComponent() {
  const [count, setCount] = useState(0);
  const { supportData, setSupportData } = useMyContext();
  async function supportApiCall() {
    const response = await axios.get(ENDPOINT, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    setSupportData({
      account: response.data.Account,
      payment: response.data.Payment,
      bookings: response.data.Bookings,
      review: response.data.Review,
      appUsage: response.data.AppUsage
    });
  }
  useEffect(() => {
    if (supportData.account.length === 0) supportApiCall();
  }, []);

  // supportData.account.length > 0
  return (
    <div className='support-page'>
      {supportData.account.length > 0 ? (
        <div className='support-page-main'>
          <HeaderComponent pageName='Support' count={count} setCount={setCount} />
          <div>
            <p>Account & App Usage</p>
            {supportData.account.map((item: { question: string; answer: string }) => {
              return (
                <div key={item.question}>
                  <Faq question={item.question} answer={item.answer} />
                </div>
              );
            })}
            {supportData.appUsage.map((item: { question: string; answer: string }) => {
              return (
                <div key={item.question}>
                  <Faq question={item.question} answer={item.answer} />
                </div>
              );
            })}
          </div>
          <div>
            <p>Payments</p>
            {supportData.payment.map((item: { question: string; answer: string }) => {
              return (
                <div key={item.question}>
                  <Faq question={item.question} answer={item.answer} />
                </div>
              );
            })}
          </div>
          <div>
            <p>Bookings</p>
            {supportData.bookings.map((item: { question: string; answer: string }) => {
              return (
                <div key={item.question}>
                  <Faq question={item.question} answer={item.answer} />
                </div>
              );
            })}
          </div>
          <div>
            <p>Reviews</p>
            {supportData.review.map((item: { question: string; answer: string }) => {
              return (
                <div key={item.question}>
                  <Faq question={item.question} answer={item.answer} />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className='flex-1 h-[100vh] flex justify-center items-center'>
          <MainLoader />
        </div>
      )}
    </div>
  );
}

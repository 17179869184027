import React, { useState, useEffect } from "react";
//import profilepic from "../../assets/profilepic.svg";
import plannerpic from "../../assets/plannerpic.svg";
import HeaderComponent from "./HeaderComponent";
import axios from "axios";
import { formatPrice } from "../../utils/reuseableFunctions";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../utils/AppRoutes";
import { useMyContext } from "../../ContextApi";
import MainLoader from "./MainLoader";

// const baseUrl = process.env.REACT_APP_API_URL;

export default function OfferVComponent() {
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [isScreenSmall, setIsScreenSmall] = useState<boolean>(false);
  const [count, setCount] = useState(0);
  const { proposals, setProposals, offerRef } = useMyContext();
  function checkScreenResize() {
    if (window.innerWidth <= 768) {
      setIsScreenSmall(true);
    } else {
      setIsScreenSmall(false);
    }
  }
  const handleClick = (id: string) => {
    navigate(AppRoutes.V_OFFER_DETAILS.replace(":offerId", id));
  };

  async function getAllProposals() {
    try {
      const response = await axios.get(`https://partypal-api.onrender.com/api/proposals/vendor`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.data.status === 200) {
        setProposals(response.data.proposals);
      } else {
        setProposals([]);
      }
    } catch (error) {
      setProposals([]);
    }
  }

  useEffect(() => {
    checkScreenResize();
    window.addEventListener("resize", checkScreenResize);
    return () => {
      window.removeEventListener("resize", checkScreenResize);
    };
  }, []);

  useEffect(() => {
    if (proposals.length === 0) {
      getAllProposals();
    }
    offerRef.current = true;
  }, [isScreenSmall]);

  return (
    <div className='offer-page'>
      {proposals.length < 1 && !offerRef.current ? (
        <div className='flex-1 h-[100vh] flex justify-center items-center'>
          <MainLoader />
        </div>
      ) : (
        <div className='offer-main'>
          <HeaderComponent pageName='Offer' count={count} setCount={setCount} />
          <>
            {!isScreenSmall ? (
              <>
                {proposals.length > 0 ? (
                  <div className='order-sometimes offer'>
                    <table>
                      <tr>
                        <th>Planner</th>
                        <th>Planner&apos;s price</th>
                        <th>My price</th>
                        <th>Status</th>
                      </tr>
                      {proposals.map(({ planner, _id, offeredAmount, proposedAmount, status }) => {
                        return (
                          <tr onClick={() => handleClick(_id)} key={_id}>
                            <td>
                              <img src={plannerpic} alt='planner picture' />
                              <p>{planner}</p>
                            </td>
                            <td>{formatPrice(offeredAmount.toString())}</td>
                            <td>{formatPrice(proposedAmount.toString())}</td>
                            <td className='accepted'>
                              <div className={status}>{status}</div>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                ) : (
                  <div className='no-offer-page'>
                    <p>No Offer</p>
                    <p>No Recent Offer</p>
                  </div>
                )}
              </>
            ) : proposals.length > 0 ? (
              <div className='offer-mobile'>
                {proposals.map(({ planner, _id, status }) => {
                  return (
                    <div onClick={() => handleClick(_id)} key={_id}>
                      <div>
                        <img src={plannerpic} alt='planner picture' />
                        <p>{planner}</p>
                      </div>
                      <div className={status}>{status}</div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className='no-offer-page'>
                <p>No Offer</p>
                <p>No Recent Offer</p>
              </div>
            )}
          </>
        </div>
      )}
    </div>
  );
}

import axios from "axios";
import React, { MutableRefObject, useRef, useState } from "react";
import { createContext, useContext, ReactNode, Dispatch, SetStateAction } from "react";

interface MyObject {
  key1: string;
  key2: number;
  category: string;
  jobCategory: string;
  _id: string;
}
interface FetchUserDataResponse {
  userData?: Props;
  status: number;
  errorMessage?: string;
}
interface userDetailsFromGoogleLogin {
  email: string;
  last_name: string;
  first_name: string;
  full_name: string;
  token: string;
}
interface Props {
  email: string;
  lastname: string;
  firstname: string;
  phonenumber: string;
  jobCategory: string;
  socialMediaHandle: string;
  location: string;
  profilePicture: string;
  username: string;
}

interface transactionObj{
  status: string,
  amount: number,
  description: string,
  date: string,
  reference: string,
  _id: string
}
interface eventObj {
  _id: string;
  date: string;
  venue: string;
  description: string;
}

interface offerObj {
  _id: string;
  date: string;
  venue: string;
  eventTitle: string;
  plannerName: string;
}

interface offerRequestObj {
  offeredAmount: number | string;
  _id: string;
  venue: string;
  plannerName: string;
  time: string;
  date: string;
  eventTitle: string;
}

interface proposalObj {
  planner: string;
  _id: string;
  offeredAmount: number | string;
  proposedAmount: number | string;
  status: string;
  time: string;
  vendorId: string;
}

interface supportDataObj {
  account: [];
  payment: [];
  bookings: [];
  review: [];
  appUsage: [];
}

interface jobCategory {
  _id: string;
  name: string;
  abbrevation: string;
}

interface walletObj{
  tokenBalance: number;
  transactions: transactionObj[],
  _id : string
}

interface MyContextData {
  myObject: MyObject;
  setMyObject: Dispatch<SetStateAction<MyObject>>;
  userDetails: userDetailsFromGoogleLogin;
  setUserDetails: Dispatch<SetStateAction<userDetailsFromGoogleLogin>>;
  isLogOutModalOpen: boolean;
  setIsLogOutModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userInfo: Props;
  setUserInfo: React.Dispatch<React.SetStateAction<Props>>;
  isMobileSidebarOpen: boolean;
  setIsMobileSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fcmToken: string;
  setFcmToken: React.Dispatch<React.SetStateAction<string>>;
  upcomingEvents: eventObj[];
  setUpcomingEvents: Dispatch<SetStateAction<eventObj[]>>;
  offers: offerObj[];
  setOffers: Dispatch<SetStateAction<offerObj[]>>;
  offerRequest: offerRequestObj[];
  setOfferRequest: Dispatch<SetStateAction<offerRequestObj[]>>;
  proposals: proposalObj[];
  setProposals: Dispatch<SetStateAction<proposalObj[]>>;
  historyProposals: proposalObj[];
  setHistoryProposals: Dispatch<SetStateAction<proposalObj[]>>;
  supportData: supportDataObj;
  setSupportData: Dispatch<SetStateAction<supportDataObj>>;
  dashboardRef: MutableRefObject<boolean>;
  offerRequestRef: MutableRefObject<boolean>;
  offerRef: MutableRefObject<boolean>;
  historyRef: MutableRefObject<boolean>;
  jobCategories: jobCategory[];
  setJobCategories: Dispatch<SetStateAction<jobCategory[]>>;
  wallet: walletObj;
  setWallet:  Dispatch<SetStateAction<walletObj>>
}

const MyContext = createContext<MyContextData | undefined>(undefined);

// Create a provider component to wrap your app with
export const MyContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLogOutModalOpen, setIsLogOutModalOpen] = useState(false);
  const [myObject, setMyObject] = React.useState<MyObject>({
    key1: "",
    key2: 0,
    category: "",
    jobCategory: "",
    _id: ""
  });
  const [userDetails, setUserDetails] = React.useState<userDetailsFromGoogleLogin>({
    email: "",
    last_name: "",
    first_name: "",
    full_name: "",
    token: ""
  });
  const [userInfo, setUserInfo] = React.useState<Props>({
    email: "",
    lastname: "",
    firstname: "",
    phonenumber: "",
    jobCategory: "",
    socialMediaHandle: "",
    location: "",
    profilePicture: "",
    username: ""
  });
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [fcmToken, setFcmToken] = useState("");
  const [upcomingEvents, setUpcomingEvents] = useState<eventObj[]>([]);
  const [offers, setOffers] = useState<offerObj[]>([]);
  const [offerRequest, setOfferRequest] = useState<offerRequestObj[]>([]);
  const [proposals, setProposals] = useState<proposalObj[]>([]);
  const [historyProposals, setHistoryProposals] = useState<proposalObj[]>([]);
  const [supportData, setSupportData] = useState<supportDataObj>({
    account: [],
    payment: [],
    bookings: [],
    review: [],
    appUsage: []
  });
  const [jobCategories, setJobCategories] = useState<jobCategory[]>([]);
  const [wallet,setWallet] = useState<walletObj>({
    tokenBalance: 0,
    transactions: [],
    _id: ""
  })
  const dashboardRef = useRef<boolean>(false);
  const offerRequestRef = useRef<boolean>(false);
  const offerRef = useRef<boolean>(false);
  const historyRef = useRef<boolean>(false);

  const userInfoData = sessionStorage.getItem("userInfo")

  if(userInfo.email === "" && userInfoData){
    const paersedUserInfoData = JSON.parse(userInfoData)
    setUserInfo(paersedUserInfoData)
  }

  return (
    <MyContext.Provider
      value={{
        myObject,
        setMyObject,
        userDetails,
        setUserDetails,
        userInfo,
        setUserInfo,
        isLogOutModalOpen,
        setIsLogOutModalOpen,
        isMobileSidebarOpen,
        setIsMobileSidebarOpen,
        upcomingEvents,
        setUpcomingEvents,
        offers,
        setOffers,
        offerRequest,
        setOfferRequest,
        proposals,
        setProposals,
        historyProposals,
        setHistoryProposals,
        supportData,
        setSupportData,
        dashboardRef,
        offerRequestRef,
        offerRef,
        historyRef,
        fcmToken,
        setFcmToken,
        jobCategories,
        setJobCategories,
        wallet,
        setWallet
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export const useMyContext = () => {
  const context = useContext(MyContext);

  if (!context) {
    throw new Error("useMyContext must be used within a MyContextProvider");
  }

  return context;
};

export const fetchData = async (): Promise<FetchUserDataResponse> => {
  const id = sessionStorage.getItem("_id");
  const token = sessionStorage.getItem("token");

  try {
    const response = await axios.get(`https://partypal-api.onrender.com/api/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    if (response.status === 200) {
      const userData: Props = {
        email: response.data.user.email,
        lastname: response.data.user.lastName,
        firstname: response.data.user.firstName,
        phonenumber: response.data.user.phoneNumber,
        jobCategory: response.data.user.jobCategory,
        socialMediaHandle: response.data.user.socialMediaHandle,
        location: response.data.user.homeAddress,
        profilePicture: response.data.user.profilePicture,
        username: response.data.user.username
      };
      return {
        userData,
        status: response.status
      };
    } else {
      return {
        status: response.status,
        errorMessage: response.data.errors[0]
      };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

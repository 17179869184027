import React, { useEffect, useState } from "react";
import { AppRoutes } from "../../../../utils/AppRoutes";

import PlannerHeader from "../Header/Header";

import "./EventStyles.css";

import { useNavigate, useParams } from "react-router-dom";
import SwitchRole from "../../SwitchRole";
import axios from "axios";
import { Toaster } from "react-hot-toast";
import { useMyContext } from "../../../../ContextApi";
import PlannerSidebarComponent from "../PlannerSidebar";

// const baseUrl = process.env.REACT_APP_API_URL;

interface proposalObject {
  category: string;
  _id: string;
}

const EventDetails = () => {
  const { myObject, setMyObject } = useMyContext();
  // console.log(myObject)
  const [eventDetails, setEventDetails] = useState({
    _id: "",
    title: "",
    datetime: "",
    description: "",
    venue: "",
    planner: "",
    skills: [],
    type: "",
    isCompleted: false,
    vendors: [],
    proposals: [],
    createdAt: "",
    updatedAt: ""
  });
  const token = sessionStorage.getItem("token");
  const [activeTab, setActiveTab] = useState("myevent");
  const { isMobileSidebarOpen, setIsMobileSidebarOpen } = useMyContext();
  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);
  const [proposalArr, setProposalArr] = useState<proposalObject[]>([]);
  const navigate = useNavigate();
  const { eventId } = useParams();
  const [showDropdown, setShowDropdown] = useState(false);
  console.log(showDropdown);
  const deteleEvent = async () => {
    try {
      await axios.delete(`https://partypal-api.onrender.com/api/events/${eventId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      navigate(AppRoutes.PLANNER.EVENTS);
    } catch (error) {
      console.log(error);
    }
  };

  const getEventDetails = async () => {
    try {
      const response = await axios.get(`https://partypal-api.onrender.com/api/events/${eventId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.status == 200) {
        setEventDetails(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  function filterArray(array: proposalObject[]) {
    const newArray = [];
    for (let i = 0; i < array.length; i++) {
      if (i == 0) {
        newArray.push(array[i]);
      } else {
        let isPresent;
        for (let j = 0; j < newArray.length; j++) {
          if (array[i].category == newArray[j].category) {
            isPresent = true;
            break;
          } else {
            continue;
          }
        }
        if (isPresent) {
          continue;
        } else {
          newArray.push(array[i]);
        }
      }
    }
    return newArray;
  }

  const getOffersOfAnEvent = async () => {
    try {
      const response = await axios.get(`https://partypal-api.onrender.com/api/offer/event/${eventId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.data.status === 200) {
        const Array = response.data.offers.map((offer: proposalObject) => {
          return { category: offer.category, _id: offer._id };
        });
        setProposalArr(filterArray(Array));
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  useEffect(() => {
    getEventDetails();
  }, []);

  useEffect(() => {
    getOffersOfAnEvent();
  }, []);

  if (!eventId) {
    return <div>Error: Event ID not provided</div>;
  }

  // const selectedEvent = events.find(
  // 	(event) => event.id === parseInt(eventId, 10)
  // );

  const handleClick = (e: React.MouseEvent) => {
    const targetImage = e.target as HTMLImageElement;
    if (targetImage.alt === "dashboard-image") return;
    setIsMobileSidebarOpen(false);
  };

  return (
    <div className='planner-event-details-page' onClick={handleClick}>
      <Toaster />
      <PlannerSidebarComponent
        isMobileSidebarOpen={isMobileSidebarOpen}
        setIsMobileSidebarOpen={setIsMobileSidebarOpen}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        component='planner'
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />
      <div className='planner-event-details'>
        <PlannerHeader
          title='My event'
          // previousLink={() => navigate(AppRoutes.PLANNER.EVENTS)}
        />

        <div className='planner-vendors'>
          <div className='event-name'>
            <h1>
              {String(eventDetails.title).length >= 30 && eventDetails
                ? `${String(eventDetails.title).substring(0, 30)}...`
                : eventDetails.title}
            </h1>
            <div className='ellipses-box' onClick={() => setShowDropdown(!showDropdown)}>
              <img src='/ellipses.svg' alt='ellipses' className='desktop-ellipses' />
              <img src='/vertical-ellipses.svg' alt='ellipses' className='mobile-ellipses' />
            </div>
            {showDropdown && (
              <div className='event-details-dropdown' onClick={deteleEvent}>
                <img src='/delete.svg' alt='delete-icon' />
                <p>Delete event</p>
              </div>
            )}
          </div>
          {proposalArr.length > 0 ? (
            proposalArr.map(({ category, _id }, index) => (
              <div
                className='planner-vendor'
                key={index}
                onClick={() => {
                  setMyObject({
                    ...myObject,
                    category
                  });
                  navigate(AppRoutes.PLANNER.VENDOR.replace(":eventId", eventId).replace(":vendor", category).replace(":offerId", _id));
                }}
                // onClick={() => navigate(AppRoutes.PLANNER.VENDOR)}
              >
                <h2>{category}</h2>
                <div
                  className='services-info-btn'
                  // onClick={() => navigate(AppRoutes.PLANNER.VENDOR)}
                >
                  <svg width='11' height='20' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M0.466853 19.0716C0.583694 19.1888 0.722498 19.2817 0.875313 19.3451C1.02813 19.4085 1.19195 19.4412 1.3574 19.4412C1.52285 19.4412 1.68667 19.4085 1.83949 19.3451C1.9923 19.2817 2.1311 19.1888 2.24794 19.0716L10.61 10.7096C10.7033 10.6165 10.7773 10.5059 10.8278 10.3841C10.8783 10.2624 10.9043 10.1319 10.9043 10.0001C10.9043 9.86835 10.8783 9.73785 10.8278 9.61612C10.7773 9.49439 10.7033 9.38381 10.61 9.29072L2.24794 0.928642C1.75487 0.435572 0.959924 0.435572 0.466853 0.928642C-0.0262175 1.42171 -0.0262175 2.21666 0.466853 2.70973L7.75222 10.0052L0.456791 17.3006C-0.0262165 17.7836 -0.0262175 18.5886 0.466853 19.0716Z'
                      fill='#262626'
                    />
                  </svg>
                </div>
              </div>
            ))
          ) : (
            <p>Add a vendor</p>
          )}
        </div>
        <div className='add-service-btn' onClick={() => navigate(AppRoutes.PLANNER.VENDOR_REQUEST.replace(":eventId", eventId))}>
          <svg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M32.818 16.9999H1.18164M16.9998 1.18176V32.8181'
              stroke='white'
              strokeWidth='1.97579'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          <p>Add vendor</p>
        </div>
      </div>
      {isSwitchUserModalOpen && (
        <SwitchRole isSwitchUserModalOpen={isSwitchUserModalOpen} setIsSwitchUserModalOpen={setIsSwitchUserModalOpen} component='planner' />
      )}
    </div>
  );
};

export default EventDetails;

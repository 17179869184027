import React, { ChangeEvent, useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import toast, { Toaster } from "react-hot-toast";
import HeaderComponent from "./HeaderComponent";
import initialValuesSchema from "../../utils/helpers";
import { convertFileToBase64 } from "../../utils/helpers";
import ReportEventModal from "./Planner/Modals/ReportEventModal/EventModal";
import { FormValues, TypeOptions } from "../../utils/helpers";
import { Spinner } from "./loader";

export default function ReportEventComponent() {
  const [count, setCount] = useState(1);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const formRef = useRef<any>(null);

  const baseScriptURL = "https://script.google.com/macros/s/AKfycbzGEtc4bsYXP_siosILMH4ShZ9DegJlmLFf5_WExrNGIcZ7RLI0IBJplMCmzo8FHapv/exec";

  const changeHandler2 = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedFile(file);
    }
  };

  const handleDateChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    // eslint-disable-next-line no-unused-vars
    setFieldValue: (field: string, value: string) => void
  ) => {
    const inputValue = e.target.value;
    if (/^\d{0,4}-?\d{0,2}-?\d{0,2}$/.test(inputValue)) {
      setFieldValue("Date", inputValue);
    }
  };

  const handleSubmit = async (values: FormValues, { setSubmitting, resetForm }: any) => {
    try {
      const formData = new FormData(formRef.current);

      if (selectedFile) {
        const base64String = await convertFileToBase64(selectedFile);
        formData.append("Image", base64String);
      }

      const response = await fetch(baseScriptURL, {
        method: "POST",
        body: formData
      });

      if (response.ok) {
        console.log("Data submitted successfully");
        setShowModal(true);
        setSelectedFile(null);
        resetForm();
        setTimeout(() => {
          setShowModal(false);
        }, 3000);
      } else {
        toast.error("Failed to submit data");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("Error submitting data");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className='report-event-page'>
      <div className='report-event-main'>
        <HeaderComponent pageName='Report Event' count={count} setCount={setCount} />
        <div className=''>
          {showModal && (
            <ReportEventModal
              message='Your report has been sent out'
              description='we will get back to you shortly '
              closeModal={() => setShowModal(false)}
            />
          )}
          <Toaster position='top-center' />
        </div>
        <div className='report-event-main-content'>
          <Formik
            initialValues={{
              Title: "",
              Date: "",
              Type: "",
              Description: "",
              Image: ""
            }}
            validationSchema={initialValuesSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form ref={formRef}>
                <p className='report-form-title'>Please, fill in the details of the event</p>
                <div className='report-form-main'>
                  <label>Event details</label>
                  <Field type='text' placeholder='Event title' name='Title' className='input-error' />
                  <ErrorMessage name='Title' component='p' className='text-[12px] h-px -mt-2 text-red-400' />
                  <br />
                  <Field
                    type='date'
                    placeholder='yyyy-mm-dd'
                    name='Date'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDateChange(e, setFieldValue)}
                    className=''
                  />

                  <ErrorMessage name='Date' component='p' className='text-[12px] h-px -mt-2 text-red-400' />
                  <br />
                  <label>Issue details</label>
                  <div className='event-select bg-[#f1f5fa] mb-5'>
                    <Field as='select' type='text' placeholder='Type of issue' name='Type' className='outline-none bg-transparent'>
                      <option value=''>Categories</option>
                      {TypeOptions.map((location, index) => (
                        <option key={index} value={location}>
                          {location}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name='Type' component='p' className='text-[12px] h-2 pt-1   bg-white text-red-400' />
                  </div>

                  <div className='text-area'>
                    <Field as='textarea' placeholder='Description of the issue' name='Description' />
                    <br />
                    <div className='flex justify-end'>
                      <ErrorMessage name='Description' component='p' className='text-[12px] h-px w-full text-red-400' />
                      <p className='flex justify-end'>{values.Description.length}/150</p>
                    </div>
                  </div>

                  <label className=''>Upload photo(optional)</label>
                  <div className='file-container'>
                    <input type='file' name='Image' className='file-input cursor-pointer' onChange={changeHandler2} />
                    <span>
                      {selectedFile ? (
                        <div className='w-full flex cursor-pointer justify-between'>
                          {" "}
                          <p className='truncate w-60'>{selectedFile.name} </p>{" "}
                          <button className=' w-[140px] cursor-pointer rounded-md border border-[#1c5d99] text-[#1c5d99] ml-auto'>
                            Change File
                          </button>
                        </div>
                      ) : (
                        "Drop picture or any related"
                      )}
                    </span>
                  </div>

                  <button type='submit' className='report-submit cursor-pointer' disabled={isSubmitting}>
                    {isSubmitting ? (
                      <div className='h-6 w-6'>
                        <Spinner />{" "}
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { ChangeEvent, useState } from "react";

import "./CancelandDeclineStyles.css";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const buttons = [
  { id: 1, text: "Price was too high", img: "/price.svg", alt: "price-icon" },
  { id: 2, text: "Ratings too low", img: "/price.svg", alt: "star-icon" },
  {
    id: 3,
    text: "Didn’t like their IG work",
    img: "/blue-instagram.svg",
    alt: "instagram-icon"
  }
];

// const baseUrl = process.env.REACT_APP_API_URL;

const DeclineOffer = ({
  closeModal,
  proposalId,
  setReloadPendingOffers
}: {
  closeModal: () => void;
  proposalId: string;
  setReloadPendingOffers: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const token = sessionStorage.getItem("token");
  const [selectedButtonId, setSelectedButtonId] = useState<number | null>(null);
  const [otherReasonText, setOtherReasonText] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);

  const handleButtonClick = (text: string) => {
    setBtnDisabled(false);
    const buttonId = buttons.findIndex((e) => e.text === text);
    setSelectedButtonId(buttonId + 1);
    // setListedReason(text)
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSelectedButtonId(null);
    if (e.target.value.length > 0) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
    setOtherReasonText(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const button = buttons.find((e) => selectedButtonId === e.id);
    if (button) {
      // console.log(button.text)
      try {
        const response = await axios.patch(
          `https://partypal-api.onrender.com/api/proposal/reject/${proposalId}`,
          {
            declineReason: button.text
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (response.data.status === 200) {
          closeModal();
          setReloadPendingOffers(true);
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      } catch (error: any) {
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An Error Occurred");
        }
      }
    } else {
      console.log("Other reason: ", otherReasonText);
      try {
        const response = await axios.patch(
          `https://partypal-api.onrender.com/api/proposal/reject/${proposalId}`,
          {
            declineReason: otherReasonText
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (response.data.status === 200) {
          closeModal();
          setReloadPendingOffers(true);
          toast.success(response.data.message);
        } else {
          toast.error("an error occurred");
        }
      } catch (error: any) {
        if (error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An Error Occurred");
        }
      }
    }
  };

  return (
    <>
      <Toaster />
      <div className='planner-modal cancel-and-decline-modal'>
        <h1 className='cancel-and-decline-heading'>Why do you want to decline?</h1>
        <form onSubmit={handleSubmit} className='cancel-and-decline-form'>
          {buttons.map(({ id, text, img, alt }) => (
            <button
              key={id}
              className='reason'
              type='button'
              onClick={() => handleButtonClick(text)}
              style={{
                backgroundColor: selectedButtonId === id ? "#ebf6ff" : "#ffffff"
              }}
            >
              <img src={img} alt={alt} />
              <p>{text}</p>
            </button>
          ))}
          <div className='other-reason'>
            <label htmlFor='other-reason'>Other</label>
            <input
              id='other-reason'
              type='text'
              value={otherReasonText}
              onChange={handleInputChange}
              placeholder='You can state your  reason'
            />
          </div>
          <button className='modal-btn decline-offer-btn' disabled={btnDisabled}>
            Decline offer
          </button>
        </form>
        <div className='close-modal' onClick={closeModal}>
          <img src='/close-icon.svg' alt='close-icon' />
        </div>
      </div>
      <div className='planner-modal-overlay' onClick={closeModal}></div>
    </>
  );
};

export default DeclineOffer;

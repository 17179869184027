import React from "react";
import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { AppRoutes } from "../../../../utils/AppRoutes";
import PlannerSidebarComponent from "../PlannerSidebar";

import "./EventVendorsStyles.css";

import PlannerHeader from "../Header/Header";
//import SidebarComponent from "../../SidebarComponent";
import OrderSummary from "../OrderSummary/OrderSummary";
import CancelRequest from "../Modals/CancelandDeclineModals/CancelRequest";
import DeclineOffer from "../Modals/CancelandDeclineModals/DeclineOffer";
import AcceptOffer from "../Modals/AcceptOfferModal/AcceptOffer";
import SuccessModal from "../Modals/SuccessModal/SuccessModal";

import { useMyContext } from "../../../../ContextApi";
import axios from "axios";
import { formatPrice } from "../../../../utils/reuseableFunctions";

// const baseUrl = process.env.REACT_APP_API_URL;

interface vendorObj {
  eventId: string;
  vendor: string;
  proposedAmount: number;
  offeredAmount: number;
  status: string;
  _id: string;
  vendorHandle: string;
  vendorId: string;
}

interface selectedVendorProps {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  jobCategory: string;
  socialMediaHandle: string;
}

const EventVendors = () => {
  const token = sessionStorage.getItem("token");
  const {
    myObject: { category }
  } = useMyContext();
  const { eventId } = useParams();
  const [showDropdown, setShowDropdown] = useState(false);
  const [openOrderSummary, setOpenOrderSummary] = useState(false);

  const [activeTab, setActiveTab] = useState("myevent");
  const [event, setEvent] = useState({
    title: "",
    description: ""
  });
  const [vendors, setVendors] = useState<vendorObj[]>([]);
  const [proposalAccepted, setProposalAccepted] = useState<vendorObj[]>([]);
  const [acceptOfferModal, setAcceptOfferModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [vendorName, setVendorName] = useState("");
  const [proposedAmount, setProposedAmount] = useState("");
  const [proposedId, setProposalId] = useState("");
  const [declineOffer, setDeclineOffer] = useState(false);
  const [isProposalAccepted, setIsProposalAccepted] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState<selectedVendorProps>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    jobCategory: "",
    socialMediaHandle: ""
  });
  const [reloadPendingOffers, setReloadPendingOffers] = useState(false);
  const navigate = useNavigate();
  const getAEvent = async () => {
    try {
      const response = await axios.get(`https://partypal-api.onrender.com/api/events/${eventId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.status == 200) {
        setEvent(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const closeDropdownModal = () => {
    setIsDropdownOpen(false);
    setShowDropdown(false);
  };
  const openDropdownModal = () => {
    setIsDropdownOpen(true);
  };

  const viewOrderSummary = () => {
    setOpenOrderSummary(true);
  };

  const closeOrderSummary = () => {
    setOpenOrderSummary(false);
  };

  const closeSuccessModal = () => {
    setSuccessModal(false);
  };

  const closeDelineOfferModal = () => {
    setDeclineOffer(false);
  };

  const openDelineOfferModal = (id: string) => {
    setDeclineOffer(true);
    setProposalId(id);
  };

  const getPendingProposals = async () => {
    try {
      const response = await axios.get(
        `https://partypal-api.onrender.com/api/proposal/${eventId}/search?status=pending&category=${category}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (response.data.status === 200) {
        setVendors(response.data.proposals);
      } else {
        setVendors([]);
      }
    } catch (error) {
      setVendors([]);
    }
  };

  const getAcceptedProposals = async () => {
    try {
      const response = await axios.get(
        `https://partypal-api.onrender.com/api/proposal/${eventId}/search?status=accepted&category=${category}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (response.data.status === 200) {
        setProposalAccepted(response.data.proposals);
        try {
          const response2 = await axios.get(`https://partypal-api.onrender.com/api/users/${response.data.proposals[0].vendorId}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          if (response2.data.status === 200) {
            setSelectedVendor(response2.data.user);
          } else {
            setSelectedVendor({
              firstName: "",
              lastName: "",
              email: "",
              phoneNumber: "",
              jobCategory: "",
              socialMediaHandle: ""
            });
          }
          console.log(response2);
        } catch (error) {
          setSelectedVendor({
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            jobCategory: "",
            socialMediaHandle: ""
          });
        }
      } else {
        setProposalAccepted([]);
      }
    } catch (error) {
      setProposalAccepted([]);
    }
  };

  useEffect(() => {
    getAEvent();
  }, []);

  useEffect(() => {
    getAcceptedProposals();
  }, [isProposalAccepted]);

  useEffect(() => {
    getPendingProposals();
  }, [reloadPendingOffers]);

  if (category == "") {
    return <Navigate to={AppRoutes.PLANNER.EVENTS} />;
  }

  return (
    <>
      <div className='event-vendors-page'>
        <PlannerSidebarComponent activeTab={activeTab} setActiveTab={setActiveTab} component='planner' />
        <div className='event-vendors-main'>
          <PlannerHeader
            title={category}
            color='#f1f5fa'
            spanText={String(event.title).length >= 30 ? `-${String(event.title).substring(0, 30)}...` : `-${String(event.title)}`}
          />
          <div className='event-vendors-container'>
            <div className={proposalAccepted.length > 0 && selectedVendor.firstName !== "" ? "chosenVendor" : "vendor-container"}>
              <div className='event-vendors-ellipses'>
                <img src='/vertical-ellipses.svg' onClick={openDropdown} alt='ellipse' className='mobile-ellipses' />
                <img src='/ellipses.svg' alt='' onClick={openDropdown} className='desktop-ellipses' />
                {showDropdown && (
                  <div className='event-vendors-dropdown'>
                    <div className='view-order' onClick={viewOrderSummary}>
                      <img src='/view-icon.svg' alt='view-icon' />
                      <p>View order</p>
                    </div>
                    <div onClick={openDropdownModal}>
                      <img src='/delete.svg' alt='delete-icon' />
                      <p>{`Cancel ${category} request`}</p>
                    </div>
                  </div>
                )}
              </div>

              {proposalAccepted.length == 0 ? (
                vendors.length > 0 ? (
                  <div className='event-vendors'>
                    <p className='vendors-heading'>Vendors</p>
                    <div className='event-vendors-offers'>
                      {vendors.map(({ _id, vendor, proposedAmount, vendorHandle, vendorId }) => (
                        <div className='event-vendor' key={_id}>
                          <div className='event-vendor-top'>
                            <img
                              className='event-vendor-pic'
                              src={`/pic1.svg`}
                              alt='profile-pic'
                              onClick={() => navigate(AppRoutes.PLANNER.VENDOR_DETAILS.replace(":vendorId", vendorId))}
                            />
                            <p>{vendor}</p>
                            <p className='event-vendor-price'>{`${formatPrice(proposedAmount.toString())}`}</p>
                          </div>
                          <div className='event-vendor-middle'>
                            <div>
                              <img src='/star-icon.svg' alt='star-icon' />
                              <p>
                                5.0 <span>(20)</span>
                              </p>
                            </div>
                            <div className='instagram-handle'>
                              <img src='/instagram-icon.svg' alt='instagram-icon' />
                              <a href={`https://www.instagram.com/${vendorHandle}`}>{vendorHandle}</a>
                            </div>
                          </div>
                          <div className='event-vendor-buttons'>
                            <button
                              className='accept-offer'
                              onClick={() => {
                                setAcceptOfferModal(true);
                                setVendorName(vendor);
                                setProposedAmount(formatPrice(proposedAmount.toString()));
                                setProposalId(_id);
                              }}
                            >
                              Accept Offer
                            </button>
                            <button className='decline-offer' onClick={() => openDelineOfferModal(_id)}>
                              Decline Offer
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className='no-planner-event'>
                    <h2>No vendors yet</h2>
                    <p>We will update you when there is an offer</p>
                  </div>
                )
              ) : selectedVendor.firstName !== "" ? (
                <div className='vendor-details-main'>
                  <div className='vendor-details-heading-box'>
                    <div className='vendor-details-heading-box-top'>
                      <img src='/pic1.svg' alt='profile-pic' />
                      <div className='vendor-details-heading'>
                        <p className='vendor-name'>{`${selectedVendor.firstName} ${selectedVendor.lastName}`}</p>
                        <p className='vendor-type'>{`${selectedVendor.jobCategory}`}</p>
                      </div>
                    </div>
                  </div>
                  <div className='vendor-detail'>
                    <div className='vendor-details-rating'>
                      <img src='/star-icon.svg' alt='star-icon' />
                      <p>5.0</p>
                    </div>
                  </div>
                  <div className='vendor-detail'>
                    <p className='vendor-label'>Phone number</p>
                    <p>{selectedVendor.phoneNumber}</p>
                  </div>
                  <div className='vendor-detail'>
                    <p className='vendor-label'>E-mail</p>
                    <p>{selectedVendor.email}</p>
                  </div>
                  <div className='vendor-detail'>
                    <p className='vendor-label'>Instagram handle</p>
                    <p>{`@${selectedVendor.socialMediaHandle}`}</p>
                  </div>
                </div>
              ) : (
                <div className='no-planner-event'>
                  <h2>No vendors yet</h2>
                  <p>We will update you when there is an offer</p>
                </div>
              )}
            </div>

            <div className='desktop-order-summary'>
              <OrderSummary />
            </div>
          </div>

          {openOrderSummary && (
            <div className='mobile-order-summary'>
              <OrderSummary closeOrderSummary={closeOrderSummary} />
            </div>
          )}
        </div>
      </div>
      {acceptOfferModal ? (
        <AcceptOffer
          closeModal={setAcceptOfferModal}
          openSuccessModal={setSuccessModal}
          vendorName={vendorName}
          proposedAmount={proposedAmount}
          setVendorName={setVendorName}
          setProposedAmount={setProposedAmount}
          proposalId={proposedId}
          setIsProposalAccepted={setIsProposalAccepted}
        />
      ) : (
        <></>
      )}
      {successModal ? <SuccessModal message={`You have successfully booked a ${category}.`} closeModal={closeSuccessModal} /> : <></>}

      {declineOffer ? (
        <DeclineOffer closeModal={closeDelineOfferModal} proposalId={proposedId} setReloadPendingOffers={setReloadPendingOffers} />
      ) : (
        <></>
      )}

      {isDropdownOpen ? <CancelRequest closeModal={closeDropdownModal} /> : <></>}
    </>
  );
};

export default EventVendors;

import React from "react";
import { useState } from "react";
import SidebarComponent from "../../common/SidebarComponent";
import CalendarVComponent from "../../common/CalendarVComponent";
import { useMyContext } from "../../../ContextApi";

export default function CalenderPage() {
  const { isMobileSidebarOpen, setIsMobileSidebarOpen } = useMyContext();
  const [activeTab, setActiveTab] = useState("calender");
  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);

  // function handleClick(e: React.MouseEvent) {
  //   const targetImage = e.target as HTMLImageElement;
  //   console.log(targetImage);
  //   if (targetImage.alt === "dashboard-image") return;
  //   setIsMobileSidebarOpen(false);
  // }
  return (
    <div className='vendorpage'>
      <SidebarComponent
        isMobileSidebarOpen={isMobileSidebarOpen}
        setIsMobileSidebarOpen={setIsMobileSidebarOpen}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        component='vendor'
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />
      <CalendarVComponent
        component='vendor'
        isSwitchUserModalOpen={isSwitchUserModalOpen}
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />
    </div>
  );
}

import React from "react";
import { ChangeEvent, useRef, useState, MouseEvent } from "react";
import { useForm, SubmitHandler, Controller, useWatch } from "react-hook-form";
import Select, { GroupBase, StylesConfig } from "react-select";

import "./VendorRequestStyles.css";

import SuccessModal from "../Modals/SuccessModal/SuccessModal";

import PlannerHeader from "../Header/Header";

import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../utils/AppRoutes";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import SwitchRole from "../../SwitchRole";
//import { text } from "stream/consumers";
import dayjs from "dayjs";
import axios from "axios";
import { useParams } from "react-router-dom";

import toast, { Toaster } from "react-hot-toast";
import PlannerSidebarComponent from "../PlannerSidebar";
import { useMyContext } from "../../../../ContextApi";

// const baseUrl = process.env.REACT_APP_API_URL;

type SelectOptionType = {
  value: string;
  label: string;
};

const defaultValues = {
  vendor: null,
  location: null,
  time: "",
  description: "",
  image: "",
  price: ""
};
interface VendorRequestProps {
  vendor: SelectOptionType | null;
  time: string;
  description: string;
  image: File | string;
  price: string;
}

const vendorOptions = [
  { value: "musician", label: "Musician" },
  { value: "carterer", label: "Carterer" },
  { value: "MC", label: "MC" },
  { value: "Music band", label: "Music band" }
];

const VendorRequest: React.FC<unknown> = () => {
  const token = sessionStorage.getItem("token");
  const [activeTab, setActiveTab] = useState("");
  const [vendor, setVendor] = useState<string | undefined>("");
  const [isCreating, setIsCreating] = useState(false);
  const { isMobileSidebarOpen, setIsMobileSidebarOpen } = useMyContext();
  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset
  } = useForm<VendorRequestProps>({ defaultValues });

  const [dropdownIsVisible, setDropdownIsVisible] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [timeValue, setTimeValue] = useState(new Date().toLocaleTimeString());
  const [showTimeErrorMessage, setShowTimeErrorMessage] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  console.log(imageUrl);
  const navigate = useNavigate();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { eventId } = useParams();

  const openDropdown = () => {
    setDropdownIsVisible(true);
  };

  const handlePhotoOption = (option: string) => {
    if (fileInputRef.current) {
      if (option === "takePhoto") {
        fileInputRef.current.setAttribute("capture", "user");
      } else {
        fileInputRef.current.removeAttribute("capture");
      }
      fileInputRef.current.click();
    }
    setDropdownIsVisible(false);
  };

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();

    const files = e.target.files;
    if (files) {
      setValue("image", files[0]);
      reader.addEventListener("load", () => {
        setImageUrl(reader.result as string);
      });
      reader.readAsDataURL(files[0]);
    }
  };

  const watchImage = useWatch({ name: "image", control }) as string | File;
  // console.log(watchImage)
  const formatPrice = (inputValue: string) => {
    const numericValue = inputValue.replace(/[^0-9]/g, "");

    const formattedValue = `₦ ${numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

    return formattedValue;
  };

  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const formattedValue = formatPrice(inputValue);

    setValue("price", formattedValue);
  };

  const onSubmit: SubmitHandler<VendorRequestProps> = async (data) => {
    const request = { ...data, time: timeValue };
    setIsCreating(true);
    try {
      const response = await axios.post(
        `https://partypal-api.onrender.com/api/offer`,
        {
          eventId: eventId,
          category: request.vendor?.label || "",
          description: request.description,
          time: request.time,
          offeredAmount: parseInt(request.price.replace(/\D/g, "")),
          imageUrl: imageUrl
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (response.status == 200) {
        console.log(request);
        setIsCreating(false);
        setShowSuccessMessage(true);
        setVendor(request.vendor?.label);
        reset();
      } else {
        setIsCreating(false);
        toast.error(response.data.message);
      }
    } catch (error: any) {
      setIsCreating(false);
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An Error Occurred");
      }
    }
  };

  const closeModal = () => {
    setShowSuccessMessage(false);
    if (eventId) {
      navigate(AppRoutes.PLANNER.EVENT_DETAILS.replace(":eventId", eventId));
    }
  };

  const customStyles: StylesConfig<SelectOptionType, false, GroupBase<SelectOptionType>> = {
    control: (defaultStyles, state) => ({
      ...defaultStyles,
      margin: 0,
      padding: "0.3rem 1rem",
      borderRadius: "4px",
      backgroundColor: "#f1f5fa",
      cursor: "pointer",
      height: undefined,
      border: state.isFocused ? "1px solid #1c5d99" : "none",
      boxShadow: state.isFocused ? "0 0 0 1px #1c5d99" : "none"
    }),

    option: (defaultStyles, state) => ({
      ...defaultStyles,
      cursor: "pointer",
      backgroundColor: state.isSelected ? "#1C5D99" : "",
      "&:hover": {
        backgroundColor: "#4c8fce9c",
        color: "white"
      }
    }),

    indicatorSeparator: (defaultStyles) => ({
      ...defaultStyles,
      display: "none"
    })
  };

  const handleClickOutside = (event: MouseEvent) => {
    const targetImage = event.target as HTMLImageElement;
    // console.log(targetImage);
    if (targetImage.alt === "dashboard-image") return;

    setIsMobileSidebarOpen(false);
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      fileInputRef.current &&
      !fileInputRef.current.contains(event.target as Node)
    ) {
      setDropdownIsVisible(false);
    }
  };

  const handleTimeChange = (time: any) => {
    if (time.$d.toLocaleTimeString().includes("AM") || time.$d.toLocaleTimeString().includes("PM")) {
      setTimeValue(time.$d.toLocaleTimeString());
      setShowTimeErrorMessage(false);
    } else setShowTimeErrorMessage(true);
    console.log(time.$d.toLocaleTimeString());
  };

  return (
    <div className='vendor-request-page' onClick={handleClickOutside}>
      <Toaster />
      <PlannerSidebarComponent
        isMobileSidebarOpen={isMobileSidebarOpen}
        setIsMobileSidebarOpen={setIsMobileSidebarOpen}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        component='planner'
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />

      <div className='vendor-request'>
        <PlannerHeader
          title='Choose vendor'
          // previousLink={() => navigate(AppRoutes.PLANNER.EVENT_DETAILS)}
        />

        <div className='vendor-request-main'>
          <p>Create an offer</p>
          <form onSubmit={handleSubmit(onSubmit)} className='vendor-request-form'>
            <div className='form-input'>
              <Controller
                name='vendor'
                control={control}
                rules={{ required: "Please choose a vendor" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id='gender'
                    className={errors?.vendor ? "input-error" : ""}
                    name='vendor'
                    options={vendorOptions}
                    onChange={field.onChange}
                    value={field.value}
                    isSearchable
                    placeholder='Musician'
                    styles={customStyles}
                  />
                )}
              />

              {errors?.vendor && <p className='input-footer-text'>{errors?.vendor?.message}</p>}
            </div>

            <div className='form-input'>
              <TimePicker onChange={handleTimeChange} defaultValue={dayjs(new Date())} />
              {showTimeErrorMessage && <p className='input-footer-text'>Please enter a time</p>}
            </div>

            <div className='form-input'>
              <textarea
                className={errors?.description ? "input-error" : ""}
                cols={30}
                placeholder='Description'
                {...register("description", {
                  required: "Please give a description"
                })}
              ></textarea>
              {errors?.description && <p className='input-footer-text'>{errors?.description?.message}</p>}
            </div>

            <div className='image-input-container form-input' style={{ border: watchImage ? "1px dashed #1C5D99" : "none" }}>
              <div className='image-input' onClick={openDropdown}>
                <img src='/office-pin.svg' alt='office-pin' />
                <span>{watchImage ? (typeof watchImage === "string" ? watchImage : watchImage.name) : "Add Image (optional)"}</span>
                {watchImage && <span className='edit-btn'>edit</span>}
              </div>
              <input
                type='file'
                id='photoInput'
                className='request-input'
                accept='image/*'
                {...register("image", {
                  required: false,
                  onChange: handleFileInputChange
                })}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
              {errors?.image && <p className='input-footer-text'>{errors?.image?.message}</p>}

              {dropdownIsVisible && (
                <div className='image-options-dropdown' ref={dropdownRef}>
                  <div onClick={() => handlePhotoOption("takePhoto")}>
                    Photo Library <img src='/photo-library.svg' alt='photo-library' />
                  </div>
                  <div onClick={() => handlePhotoOption("selectFromLibrary")}>
                    Take Photo <img src='/camera.svg' alt='camera' />
                  </div>
                </div>
              )}
            </div>

            <div className='form-input'>
              <input
                className={errors?.price ? "input-error" : ""}
                type='text'
                placeholder='Suggest a price'
                {...register("price", {
                  required: "Please enter a price",
                  onChange: handlePriceChange
                })}
              />
              {errors?.price && <p className='input-footer-text'>{errors?.price?.message}</p>}
            </div>

            <button className='make-a-request-btn'>{isCreating ? "creating..." : "create offer"}</button>
          </form>
          {showSuccessMessage && (
            <SuccessModal
              message='Your request has been sent out'
              description={`${vendor} will reach out to you`}
              closeModal={closeModal}
            />
          )}
        </div>
      </div>
      {isSwitchUserModalOpen && (
        <SwitchRole isSwitchUserModalOpen={isSwitchUserModalOpen} setIsSwitchUserModalOpen={setIsSwitchUserModalOpen} component='planner' />
      )}
    </div>
  );
};

export default VendorRequest;

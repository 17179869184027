import React, { useState, ChangeEvent, useRef, useEffect} from "react";
import SidebarComponent from "../../common/SidebarComponent";
import WalletVComponent from "../../common/WalletVComponent";
import SwitchRole from "../../common/SwitchRole";
import {useMyContext } from "../../../ContextApi";
import axios from "axios";
import SuccessModal from "../../common/Planner/Modals/SuccessModal/SuccessModal2";
import WalletDecline from "../../common/Planner/Modals/CancelandDeclineModals/WalletDeline";

export default function WalletPage() {
  const id = sessionStorage.getItem("_id")
  const email=sessionStorage.getItem("email")
  const token = sessionStorage.getItem("token")
  const {setWallet,isMobileSidebarOpen, setIsMobileSidebarOpen } = useMyContext();
  const [activeTab, setActiveTab] = useState("wallet");
  const [walletModal, setWalletModal] = useState(false);
  const [walletModalCount, setWalletModalCount] = useState(0);
  const [amount, setAmount] = useState("");
  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);
  const appToken = useRef(0);
  const [verifyPaymentModal,setVerifyPaymentModal] = useState(false)
  const [tokenMessage,setTokenMessage] = useState("")
  const [isPaymentFailed,setIsPaymentFailed] = useState(false)

  function closeModal(){
    setIsPaymentFailed(false)
  }
  function convertToken(amount : string){
    const amountInt = parseInt(amount.replace(/[^0-9]/g, ""))
    if(Number.isNaN(amountInt)){
      appToken.current = 0
    }else{
      const noOfToken = (amountInt / 100).toFixed(1)
      appToken.current =  Number(noOfToken)

    }
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const amount = formatPrice(e.target.value)
    convertToken(amount)
    setAmount(amount);
  }
  function handleClick(e: React.MouseEvent) {
    const targetImage = e.target as HTMLImageElement;
    if (targetImage.alt === "dashboard-image") return;
    setIsMobileSidebarOpen(false);
  }

  function handleClick2(e: React.MouseEvent) {
    const targetImage = e.target as HTMLImageElement;
    if (targetImage.className === "wallet-modal") {
      setWalletModal(false);
      setAmount("");
      if(walletModalCount == 1){
        setWalletModalCount(0)
      }
    }
  }

  function handleClick3() {
    const formatAmount = amount.replace(/[^0-9]/g, "")
    if(formatAmount !== ""){
      setWalletModalCount(1);
      setAmount(formatAmount)
    }
  }

  function amountFunc(cash: string) {
    setAmount(formatPrice(cash));
    convertToken(cash)
  }

  const formatPrice = (inputValue: string) => {
    const numericValue = inputValue.replace(/[^0-9]/g, "");

    const formattedValue = `₦ ${numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

    return formattedValue;
  };

  const initializePayment = async ()=>{
    try{
      const payEndpoint = `https://partypal-api.onrender.com/api/paystack/${id}/pay`
      const response = await axios.post(payEndpoint,{
      amount: parseInt(amount),
      email: email
    },{
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    if(response.status === 200){
      localStorage.setItem("reference",response.data.reference)
      window.location.href=response.data.authorization_url
    }
    }catch(error){
      setIsPaymentFailed(true)
      setWalletModal(false)
      console.log(error)
    }
  }

  const verifyPayment = async()=>{
    const reference = localStorage.getItem("reference")
    const verifyEndpoint = `https://partypal-api.onrender.com/api/paystack/verify/${reference}`
    if(reference){
      try{
        const response = await axios.get(verifyEndpoint,{
          headers : {
            Authorization: `Bearer ${token}`
          }
        })
        if(response.status === 200){
          localStorage.removeItem("reference")
          getWallet()
          setTokenMessage(response.data.message)
          setVerifyPaymentModal(true)
        }
      }catch(error){
        setVerifyPaymentModal(false)
        setWalletModal(false)
        setIsPaymentFailed(true)
        console.log(error)
      }
    }
  }

  async function getWallet(){
    const endpoint = `https://partypal-api.onrender.com/api/users/${id}/wallet/`
    try{
      const response = await axios.get(endpoint,{
        headers:{
          Authorization : `Bearer ${token}`
        }
      })
      if(response.status === 200){
        setWallet({
          tokenBalance: response.data.wallet.tokenBalance,
          transactions: response.data.wallet.transactions,
          _id: response.data.wallet._id
      })
      }
    }catch(error){
      setWallet({
        tokenBalance: 0,
        transactions: [],
        _id: ""
      })
    }

  }



  useEffect(()=>{
    verifyPayment()
  },[])

useEffect(()=>{
  const reference = localStorage.getItem("reference")
  if(!reference){
    getWallet()
  }
},[])

  return (
    <div className='vendorpage' onClick={handleClick}>
      <SidebarComponent
        isMobileSidebarOpen={isMobileSidebarOpen}
        setIsMobileSidebarOpen={setIsMobileSidebarOpen}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        component='vendor'
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />
      <WalletVComponent walletModal={walletModal} setWalletModal={setWalletModal} />

      {walletModal && activeTab === "wallet" && (
        <div className='wallet-modal' onClick={handleClick2}>
          <div className='wallet-modal-main'>
            {walletModalCount === 0 ? (
              <div>
                <div className='wallet-container'>
                  <div>
                    <p>Top up</p>
                    <p>How much token do you want?</p>
                  </div>
                  <div>
                    <div className={amount === "1000" ? "selected" : ""} onClick={() => amountFunc("1000")}>
                      <p>N1,000</p>
                      <p>10 token</p>
                    </div>
                    <div className={amount === "2000" ? "selected" : ""} onClick={() => amountFunc("2000")}>
                      <p>N2,000</p>
                      <p>20 token</p>
                    </div>
                    <div className={amount === "3000" ? "selected" : ""} onClick={() => amountFunc("3000")}>
                      <p>N3,000</p>
                      <p>30 token</p>
                    </div>
                    <div className={amount === "10000" ? "selected" : ""} onClick={() => amountFunc("10000")}>
                      <p>10,000</p>
                      <p>100 token</p>
                    </div>
                    <div onClick={() => amountFunc("20000")} className={amount === "20000" ? "selected" : ""}>
                      <p>N20,000</p>
                      <p>200 token</p>
                    </div>
                  </div>
                  <div>
                    <p>Different amount?</p>
                    <input type='text' placeholder='amount' value={amount} onChange={handleChange} />
                    {amount ? (
                      <p>
                        {amount} gives {appToken.current} token
                      </p>
                    ) : null}
                  </div>
                </div>
                <button onClick={handleClick3}>
                  <p>Continue</p>
                  <svg className="moveleft" xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'>
                    <path
                      d='M11.5567 3.05737C11.8067 2.80741 12.1458 2.66699 12.4993 2.66699C12.8529 2.66699 13.192 2.80741 13.442 3.05737L19.442 9.05737C19.692 9.30741 19.8324 9.64649 19.8324 10C19.8324 10.3536 19.692 10.6927 19.442 10.9427L13.442 16.9427C13.1905 17.1856 12.8537 17.32 12.5041 17.3169C12.1546 17.3139 11.8201 17.1737 11.5729 16.9265C11.3257 16.6793 11.1855 16.3448 11.1825 15.9952C11.1794 15.6456 11.3138 15.3088 11.5567 15.0574L15.166 11.3334H2.49935C2.14573 11.3334 1.80659 11.1929 1.55654 10.9429C1.30649 10.6928 1.16602 10.3537 1.16602 10C1.16602 9.64642 1.30649 9.30728 1.55654 9.05723C1.80659 8.80718 2.14573 8.66671 2.49935 8.66671H15.166L11.5567 4.94271C11.3067 4.69267 11.1663 4.35359 11.1663 4.00004C11.1663 3.64649 11.3067 3.30741 11.5567 3.05737Z'
                      fill='white'
                    />
                  </svg>
                </button>
              </div>
            ) : (
              <div className='wallet-container2'>
                <p>How would you like to pay</p>
                <div className='payment-method cursor-pointer' onClick={initializePayment}>
                  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
                    <path
                      d='M1.5 18.125C1.5 18.8212 1.77656 19.4889 2.26884 19.9812C2.76113 20.4734 3.42881 20.75 4.125 20.75H19.875C20.5712 20.75 21.2389 20.4734 21.7312 19.9812C22.2234 19.4889 22.5 18.8212 22.5 18.125V10.9062H1.5V18.125ZM4.59375 14.5625C4.59375 14.1895 4.74191 13.8319 5.00563 13.5681C5.26935 13.3044 5.62704 13.1562 6 13.1562H8.25C8.62296 13.1562 8.98065 13.3044 9.24437 13.5681C9.50809 13.8319 9.65625 14.1895 9.65625 14.5625V15.5C9.65625 15.873 9.50809 16.2306 9.24437 16.4944C8.98065 16.7581 8.62296 16.9062 8.25 16.9062H6C5.62704 16.9062 5.26935 16.7581 5.00563 16.4944C4.74191 16.2306 4.59375 15.873 4.59375 15.5V14.5625ZM19.875 4.25H4.125C3.42881 4.25 2.76113 4.52656 2.26884 5.01884C1.77656 5.51113 1.5 6.17881 1.5 6.875V8.09375H22.5V6.875C22.5 6.17881 22.2234 5.51113 21.7312 5.01884C21.2389 4.52656 20.5712 4.25 19.875 4.25Z'
                      fill='#84D82B'
                    />
                  </svg>
                  <p>Pay with card</p>
                  <svg xmlns='http://www.w3.org/2000/svg' width='11' height='19' viewBox='0 0 11 19' fill='none'>
                    <path
                      d='M0.367789 18.3974C0.483904 18.5138 0.621842 18.6062 0.773705 18.6692C0.925569 18.7322 1.08837 18.7646 1.25279 18.7646C1.41721 18.7646 1.58001 18.7322 1.73187 18.6692C1.88374 18.6062 2.02168 18.5138 2.13779 18.3974L10.4478 10.0874C10.5405 9.9949 10.614 9.88501 10.6642 9.76404C10.7144 9.64306 10.7402 9.51338 10.7402 9.38241C10.7402 9.25145 10.7144 9.12176 10.6642 9.00079C10.614 8.87982 10.5405 8.76993 10.4478 8.67741L2.13779 0.367413C1.64779 -0.122587 0.857789 -0.122587 0.367789 0.367413C-0.122211 0.857412 -0.122211 1.64741 0.367789 2.13741L7.60779 9.38741L0.357791 16.6374C-0.122209 17.1174 -0.122211 17.9174 0.367789 18.3974Z'
                      fill='#1C5D99'
                    />
                  </svg>
                </div>
                <div className='payment-method cursor-pointer' onClick={initializePayment}>
                  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
                    <path
                      d='M1.5 18.125C1.5 18.8212 1.77656 19.4889 2.26884 19.9812C2.76113 20.4734 3.42881 20.75 4.125 20.75H19.875C20.5712 20.75 21.2389 20.4734 21.7312 19.9812C22.2234 19.4889 22.5 18.8212 22.5 18.125V10.9062H1.5V18.125ZM4.59375 14.5625C4.59375 14.1895 4.74191 13.8319 5.00563 13.5681C5.26935 13.3044 5.62704 13.1562 6 13.1562H8.25C8.62296 13.1562 8.98065 13.3044 9.24437 13.5681C9.50809 13.8319 9.65625 14.1895 9.65625 14.5625V15.5C9.65625 15.873 9.50809 16.2306 9.24437 16.4944C8.98065 16.7581 8.62296 16.9062 8.25 16.9062H6C5.62704 16.9062 5.26935 16.7581 5.00563 16.4944C4.74191 16.2306 4.59375 15.873 4.59375 15.5V14.5625ZM19.875 4.25H4.125C3.42881 4.25 2.76113 4.52656 2.26884 5.01884C1.77656 5.51113 1.5 6.17881 1.5 6.875V8.09375H22.5V6.875C22.5 6.17881 22.2234 5.51113 21.7312 5.01884C21.2389 4.52656 20.5712 4.25 19.875 4.25Z'
                      fill='#84D82B'
                    />
                  </svg>
                  <p>Pay with bank</p>
                  <svg xmlns='http://www.w3.org/2000/svg' width='11' height='19' viewBox='0 0 11 19' fill='none'>
                    <path
                      d='M0.367789 18.3974C0.483904 18.5138 0.621842 18.6062 0.773705 18.6692C0.925569 18.7322 1.08837 18.7646 1.25279 18.7646C1.41721 18.7646 1.58001 18.7322 1.73187 18.6692C1.88374 18.6062 2.02168 18.5138 2.13779 18.3974L10.4478 10.0874C10.5405 9.9949 10.614 9.88501 10.6642 9.76404C10.7144 9.64306 10.7402 9.51338 10.7402 9.38241C10.7402 9.25145 10.7144 9.12176 10.6642 9.00079C10.614 8.87982 10.5405 8.76993 10.4478 8.67741L2.13779 0.367413C1.64779 -0.122587 0.857789 -0.122587 0.367789 0.367413C-0.122211 0.857412 -0.122211 1.64741 0.367789 2.13741L7.60779 9.38741L0.357791 16.6374C-0.122209 17.1174 -0.122211 17.9174 0.367789 18.3974Z'
                      fill='#1C5D99'
                    />
                  </svg>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {isSwitchUserModalOpen && (
        <SwitchRole
          isSwitchUserModalOpen={isSwitchUserModalOpen}
          setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
          component='vendor'
          title='Do you want switch to planner mode?'
          description='To create an event, you need to switch to the planner.'
          btnText1='Continue as Vendor'
          btnText2='Switch to Planner'
        />
      )}
      {
        verifyPaymentModal
        &&
        <SuccessModal
          message="Congratulations!"
          description={tokenMessage}
          closeModal={()=>setVerifyPaymentModal(false)}
        />
      }
      {
        isPaymentFailed
        &&
        <WalletDecline
          message={"your transaction was unseccessful"}
          description={"Please try again!"}
          btnText={"Try again"} 
          closeModal={closeModal}
        />
      }
    </div>
  );
}

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./OrderSummaryStyles.css";
import { AppRoutes } from "../../../../utils/AppRoutes";
import { useEffect, useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import dayjs from "dayjs";
import "dayjs/locale/en";
import { formatPrice } from "../../../../utils/reuseableFunctions";
import { useMyContext } from "../../../../ContextApi";

// const baseUrl = process.env.REACT_APP_API_URL;

const OrderSummary = ({ closeOrderSummary }: { closeOrderSummary?: () => void }) => {
  const token = sessionStorage.getItem("token");
  const { eventId, offerId } = useParams();
  const {
    myObject: { category }
  } = useMyContext();
  const navigate = useNavigate();
  const [offer, setOffer] = useState({
    venue: "",
    time: "",
    description: "",
    imageUrl: "",
    category: "",
    offeredAmount: ""
  });

  const getAProp = async () => {
    try {
      const response = await axios.get(`https://partypal-api.onrender.com/api/offer/${offerId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log(response.data);
      if (response.data.status === 200) {
        setOffer(response.data.offer);
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An Error Occurred");
      }
    }
  };

  useEffect(() => {
    getAProp();
  }, []);

  return (
    <div className='order-summary'>
      <Toaster />
      <div className='order-summary-left-arrow' onClick={closeOrderSummary}>
        <svg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M8.71416 0.308523C8.61661 0.210726 8.50072 0.133134 8.37314 0.0801925C8.24555 0.0272511 8.10878 0 7.97064 0C7.83251 0 7.69574 0.0272511 7.56815 0.0801925C7.44057 0.133134 7.32468 0.210726 7.22713 0.308523L0.24569 7.28997C0.167807 7.36769 0.106018 7.46001 0.0638593 7.56164C0.0217006 7.66328 0 7.77223 0 7.88226C0 7.99229 0.0217006 8.10123 0.0638593 8.20287C0.106018 8.3045 0.167807 8.39682 0.24569 8.47454L7.22713 15.456C7.6388 15.8677 8.3025 15.8677 8.71416 15.456C9.12582 15.0443 9.12582 14.3806 8.71416 13.969L2.63165 7.87805L8.72256 1.78714C9.12582 1.38388 9.12582 0.711783 8.71416 0.308523Z'
            fill='#262626'
          />
        </svg>
        <p>Order summary</p>
      </div>
      <div className='order-summary-heading-container'>
        <p className='order-summary-heading'>Order summary</p>
        <p
          className='order-summary-edit-btn'
          onClick={() => {
            if (eventId && offerId && category) {
              navigate(
                AppRoutes.PLANNER.EDIT_VENDOR_REQUEST.replace(":eventId", eventId).replace(":offerId", offerId).replace(":vendor", category)
              );
            }
          }}
        >
          Edit order
        </p>
      </div>
      {offer.time ? (
        <div className='order-summary-details'>
          <div className='order-summary-detail'>
            <p className='order-title'>Location </p>
            <p className='order-value'>{offer.venue}</p>
          </div>
          <div className='order-summary-detail'>
            <p className='order-title'>Time</p>
            <p className='order-value'>{offer.time ? dayjs(offer.time).format("h:mm A") : ""}</p>
          </div>
          <div className='order-summary-detail'>
            <p className='order-title'>Description</p>
            <p className='order-value'>{offer.description}</p>
          </div>
          <div className='order-summary-detail image-container'>
            {offer.imageUrl ? <img src={offer.imageUrl} alt={`picture of a ${offer.category}`} /> : <p>No picture to display</p>}
          </div>
          <div className='order-summary-detail'>
            <p className='order-title'>Price</p>
            <p className='order-value'>{offer.offeredAmount ? `${formatPrice(offer.offeredAmount.toString())}` : ""}</p>
          </div>
        </div>
      ) : (
        <p>No data to display</p>
      )}
    </div>
  );
};

export default OrderSummary;

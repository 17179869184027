import React, { useState } from "react";
//import SidebarComponent from "../../SidebarComponent";
import PasswordVComponent from "../../PasswordVComponent";
import SwitchRole from "../../SwitchRole";
// import "./ChangePasswordStyles.css";
//import PlannerHeader from "../Header/Header";
import PlannerSidebarComponent from "../PlannerSidebar";
import { useMyContext } from "../../../../ContextApi";

export default function ChangePassword() {
  const { isMobileSidebarOpen, setIsMobileSidebarOpen } = useMyContext();
  const [activeTab, setActiveTab] = useState("password");
  const [passwordModal, setPasswordModal] = useState(false);
  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);

  const handleClick = (e: React.MouseEvent) => {
    const targetImage = e.target as HTMLImageElement;
    console.log(targetImage);
    if (targetImage.alt === "dashboard-image") return;
    setIsMobileSidebarOpen(false);
  };

  const handleClick2 = (e: React.MouseEvent) => {
    const targetImage = e.target as HTMLImageElement;
    console.log(targetImage);
    if (targetImage.className === "password-modal") {
      setPasswordModal(false);
    }
  };

  return (
    <div className='vendorpage' onClick={handleClick}>
      {/* <SidebarComponent
				isMobileSidebarOpen={isMobileSidebarOpen}
				setIsMobileSidebarOpen={setIsMobileSidebarOpen}
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				mobileActiveTab={mobileActiveTab}
				setMobileActiveTab={setMobileActiveTab}
				component={component}
				setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
			/> */}
      <PlannerSidebarComponent
        isMobileSidebarOpen={isMobileSidebarOpen}
        setIsMobileSidebarOpen={setIsMobileSidebarOpen}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        component='planner'
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />
      <PasswordVComponent passwordModal={passwordModal} setPasswordModal={setPasswordModal} />
      {/* <div className="password-page">
				<PlannerHeader title={"Change Password"} hideArrow={true} />
				<div className="password-page-main">
					<p>You can change your password by imputing the previous one</p>
					<div className="password-container">
						<div className="password-form">
							<div className="password">
								<input type="text" placeholder="Previous password" />
								<div className="icon-container">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
									>
										<path
											d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
											fill="#1C5D99"
										/>
										<path
											d="M18.5072 6.61781C16.4578 5.2125 14.2645 4.5 11.9887 4.5C9.94078 4.5 7.94438 5.10937 6.05484 6.30375C4.14937 7.51078 2.28141 9.70312 0.75 12C1.98844 14.0625 3.6825 16.1831 5.44687 17.3991C7.47094 18.7931 9.67172 19.5 11.9887 19.5C14.2856 19.5 16.4817 18.7936 18.5184 17.4005C20.3114 16.1719 22.0177 14.0541 23.25 12C22.0134 9.96422 20.3016 7.84875 18.5072 6.61781ZM12 16.5C11.11 16.5 10.24 16.2361 9.49993 15.7416C8.75991 15.2471 8.18314 14.5443 7.84254 13.7221C7.50195 12.8998 7.41283 11.995 7.58647 11.1221C7.7601 10.2492 8.18868 9.44736 8.81802 8.81802C9.44736 8.18868 10.2492 7.7601 11.1221 7.58647C11.995 7.41283 12.8998 7.50195 13.7221 7.84254C14.5443 8.18314 15.2471 8.75991 15.7416 9.49993C16.2361 10.24 16.5 11.11 16.5 12C16.4986 13.1931 16.0241 14.3369 15.1805 15.1805C14.3369 16.0241 13.1931 16.4986 12 16.5Z"
											fill="#1C5D99"
										/>
									</svg>
									<span className="line"></span>
								</div>
							</div>
							<div className="password">
								<input type="text" placeholder="New password" />
							</div>
							<div className="password confirm">
								<input type="text" placeholder="Previous password" />
							</div>
							<button onClick={() => setPasswordModal(true)}>
								Save changes
							</button>
						</div>
					</div>
				</div>
			</div> */}

      {passwordModal && activeTab === "password" && (
        <div className='password-modal' onClick={handleClick2}>
          <div className='password-modal-main'>
            <div>
              <svg xmlns='http://www.w3.org/2000/svg' width='246' height='254' viewBox='0 0 246 254' fill='none'>
                <circle cx='4' cy='146' r='3.5' fill='#F41C1C' />
                <circle cx='14' cy='93' r='3.5' fill='#FE0550' />
                <circle cx='56' cy='59' r='3.5' fill='#64054B' />
                <circle cx='82' cy='24' r='3.5' fill='#64054B' />
                <circle cx='130' cy='227' r='3.5' fill='#FE0550' />
                <circle cx='239' cy='44' r='3.5' fill='#F41C1C' />
                <circle cx='141' cy='37' r='3.5' fill='#EF96D6' />
                <circle cx='151' cy='4' r='3.5' fill='#F41C1C' />
                <circle cx='116' cy='24' r='3.5' fill='#FE0550' />
                <circle cx='59' cy='17' r='3.5' fill='#EF96D6' />
                <circle cx='213' cy='17' r='3.5' fill='#64054B' />
                <circle cx='98' cy='227' r='3.5' fill='#64054B' />
                <circle cx='220' cy='78' r='3.5' fill='#64054B' />
                <circle cx='239' cy='207' r='3.5' fill='#64054B' />
                <circle cx='56' cy='186' r='3.5' fill='#64054B' />
                <circle cx='223' cy='165' r='3.5' fill='#F41C1C' />
                <circle cx='70' cy='215' r='3.5' fill='#F41C1C' />
                <circle cx='242' cy='108' r='3.5' fill='#FE0550' />
                <circle cx='30' cy='108' r='3.5' fill='#EF96D6' />
                <circle cx='175' cy='214' r='3.5' fill='#EF96D6' />
                <circle cx='21' cy='193' r='3.5' fill='#EF96D6' />
                <circle cx='134' cy='250' r='3.5' fill='#F41C1C' />
                <circle cx='179' cy='63' r='3.5' fill='#FE0550' />
              </svg>
              <p>Password change was successful</p>
            </div>
          </div>
        </div>
      )}
      {isSwitchUserModalOpen && (
        <SwitchRole
          isSwitchUserModalOpen={isSwitchUserModalOpen}
          setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
          component='planner'
          title='Do you want switch to vendor mode?'
          description=''
          btnText1='Continue as Planner'
          btnText2='Switch to vendor'
        />
      )}
    </div>
  );
}

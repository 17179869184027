import React, { useEffect, useState } from 'react'
import { useMyContext } from '../../ContextApi';
import { walletTimeDifference } from '../../utils/reuseableFunctions';
import Transaction2 from './Transaction2';
import TransactionDetails from './TransactionDetails';
import TransactionMobileDetails from './TransactionMobileDetails';
import HeaderComponent from './HeaderComponent';

interface transaction{
    description : string,
    date : string,
    amount: number
  }

export default function TransactionVcomponent() {
    const [count, setCount] = useState(1);
    const [tokenDetails, setTokenDetails] = useState(false);
    const [index,setIndex] = useState("")
    const [isScreenSmall, setIsScreenSmall] = useState(false);
    const [singleTrans,setSingleTrans] = useState<transaction>({
        description: "",
        date : "",
        amount: 0
      })
    const [arrOfDate, setArrOfDate] = useState<string[]>([])
    const {wallet} = useMyContext()
    function checkScreenResize() {
        if (window.innerWidth <= 768) {
          setIsScreenSmall(true);
        } else {
          setIsScreenSmall(false);
          setTokenDetails(false);
        }
      }

    function handleClick2(id:string,description: string,date:string,amount:number) {
        setSingleTrans({
          description,
          date,
          amount
        })
      if (window.innerWidth > 768){
        setIndex(id)
        return 
      }
        setTokenDetails(true);
    }

    function arrayOfDay(){
        const newArr = [];
        const arr = wallet.transactions.map(({date})=>{
          return walletTimeDifference(date)
        }).reverse()
    
        for(let i=0; i < arr.length; i++){
          if(i == 0){
            newArr.push(arr[0])
          }else{
            if(arr[i] != arr[i-1]){
              newArr.push(arr[i])
            }else{
              continue
            }
          }
        }
        setArrOfDate(newArr)
      }

    useEffect(()=>{
        arrayOfDay()
        checkScreenResize()
        window.addEventListener("resize", checkScreenResize);
        return () => {
          window.removeEventListener("resize", checkScreenResize);
        };
    },[])

  return (
    <div className='wallet-page'>
        <div className='wallet-page-main'>
        {
            !tokenDetails ? (
            <>
                <HeaderComponent count={count} setCount={setCount} pageName='Token Transactions' />
                  <div className={`token-transaction-main ${!(wallet.transactions.length > 0) ? "justify-center items-center" : ""}`}>
                    {
                      wallet.transactions.length > 0
                      ?
                    <>
                    <div>
                    {
                      arrOfDate.map((dateDay,idx2)=>{
                        return (
                          <div key={idx2}>
                        <p className="transaction-title">{dateDay}</p>
                      {
                        wallet.transactions
                        .filter(({date})=>{
                          const day = walletTimeDifference(date)
                          return dateDay == day
                        }).reverse()
                        .map(({_id,description,date, amount})=>{
                          return (
                              <Transaction2 
                                onClick={() => handleClick2(_id,description,date,amount)} 
                                activeProp={index === _id}
                                key={_id} 
                                description={description} 
                                date={date}
                                amount={amount} 
                              />
                          )
                        })
                      }
                    </div>
                        )
                      })
                    }
                    </div>
                    {!isScreenSmall &&
                    (
                      <TransactionDetails 
                        description={singleTrans.description}  
                        date={singleTrans.date}
                        amount={singleTrans.amount}
                        />
                    )
                    }
                    </>
                      : <p className="text-center text-[#1c5d99] text-2xl font-bold">No transactions</p>
                    }
                  </div>
                </>
              ) : (
                <div className='token-details'>
                  <div className='token-details-heading'>
                    <svg onClick={() => {setTokenDetails(false)}} xmlns='http://www.w3.org/2000/svg' width='11' height='19' viewBox='0 0 11 19' fill='none'>
                      <path
                        d='M10.3724 0.367234C10.2563 0.250826 10.1184 0.158469 9.96653 0.095453C9.81467 0.0324369 9.65186 0 9.48744 0C9.32303 0 9.16022 0.0324369 9.00836 0.095453C8.8565 0.158469 8.71856 0.250826 8.60244 0.367234L0.292444 8.67723C0.199741 8.76975 0.126193 8.87964 0.0760117 9.00061C0.0258302 9.12158 0 9.25127 0 9.38223C0 9.5132 0.0258302 9.64289 0.0760117 9.76386C0.126193 9.88483 0.199741 9.99472 0.292444 10.0872L8.60244 18.3972C9.09244 18.8872 9.88245 18.8872 10.3724 18.3972C10.8624 17.9072 10.8624 17.1172 10.3724 16.6272L3.13244 9.37723L10.3824 2.12723C10.8624 1.64723 10.8624 0.847234 10.3724 0.367234Z'
                        fill='#262626'
                      />
                    </svg>
                  </div>
                  <TransactionMobileDetails 
                    description={singleTrans.description}  
                    date={singleTrans.date}
                    amount={singleTrans.amount}
                  />
                </div>
              )
        }
        </div>
    </div>
  )
}

import React, { useState } from "react";
import SidebarComponent from "../../common/SidebarComponent";
import PasswordVComponent from "../../common/PasswordVComponent";
import SwitchRole from "../../common/SwitchRole";
import { useMyContext } from "../../../ContextApi";
// import { jwtDecode } from "jwt-decode";
// import PlannerSidebarComponent from "../../common/Planner/PlannerSidebar";

export default function PasswordPage() {
  const { isMobileSidebarOpen, setIsMobileSidebarOpen } = useMyContext();
  const [activeTab, setActiveTab] = useState("password");
  const [passwordModal, setPasswordModal] = useState(false);
  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);
  //const [typeOfUser, setTypeOfUser] = useState("");

  function handleClick(e: React.MouseEvent) {
    const targetImage = e.target as HTMLImageElement;
    // console.log(targetImage)
    if (targetImage.alt === "dashboard-image") return;
    setIsMobileSidebarOpen(false);
  }

  function handleClick2(e: React.MouseEvent) {
    const targetImage = e.target as HTMLImageElement;
    console.log(targetImage);
    if (targetImage.className === "password-modal") {
      setPasswordModal(false);
    }
  }

  return (
    <div className='vendorpage' onClick={handleClick}>
      <SidebarComponent
        isMobileSidebarOpen={isMobileSidebarOpen}
        setIsMobileSidebarOpen={setIsMobileSidebarOpen}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        component='vendor'
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />
      <PasswordVComponent passwordModal={passwordModal} setPasswordModal={setPasswordModal} />

      {passwordModal && activeTab === "password" && (
        <div className='password-modal' onClick={handleClick2}>
          <div className='password-modal-main'>
            <div>
              <svg xmlns='http://www.w3.org/2000/svg' width='246' height='254' viewBox='0 0 246 254' fill='none'>
                <circle cx='4' cy='146' r='3.5' fill='#F41C1C' />
                <circle cx='14' cy='93' r='3.5' fill='#FE0550' />
                <circle cx='56' cy='59' r='3.5' fill='#64054B' />
                <circle cx='82' cy='24' r='3.5' fill='#64054B' />
                <circle cx='130' cy='227' r='3.5' fill='#FE0550' />
                <circle cx='239' cy='44' r='3.5' fill='#F41C1C' />
                <circle cx='141' cy='37' r='3.5' fill='#EF96D6' />
                <circle cx='151' cy='4' r='3.5' fill='#F41C1C' />
                <circle cx='116' cy='24' r='3.5' fill='#FE0550' />
                <circle cx='59' cy='17' r='3.5' fill='#EF96D6' />
                <circle cx='213' cy='17' r='3.5' fill='#64054B' />
                <circle cx='98' cy='227' r='3.5' fill='#64054B' />
                <circle cx='220' cy='78' r='3.5' fill='#64054B' />
                <circle cx='239' cy='207' r='3.5' fill='#64054B' />
                <circle cx='56' cy='186' r='3.5' fill='#64054B' />
                <circle cx='223' cy='165' r='3.5' fill='#F41C1C' />
                <circle cx='70' cy='215' r='3.5' fill='#F41C1C' />
                <circle cx='242' cy='108' r='3.5' fill='#FE0550' />
                <circle cx='30' cy='108' r='3.5' fill='#EF96D6' />
                <circle cx='175' cy='214' r='3.5' fill='#EF96D6' />
                <circle cx='21' cy='193' r='3.5' fill='#EF96D6' />
                <circle cx='134' cy='250' r='3.5' fill='#F41C1C' />
                <circle cx='179' cy='63' r='3.5' fill='#FE0550' />
              </svg>
              <p>Password change was successful</p>
            </div>
          </div>
        </div>
      )}
      {isSwitchUserModalOpen && (
        <SwitchRole
          isSwitchUserModalOpen={isSwitchUserModalOpen}
          setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
          component='vendor'
          title='Do you want switch to planner mode?'
          description='To create an event, you need to switch to the planner.'
          btnText1='Continue as Vendor'
          btnText2='Switch to Planner'
        />
      )}
    </div>
  );
}

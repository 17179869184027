import React, { useState } from "react";
//import SidebarComponent from "../../SidebarComponent";
import SwitchRole from "../../SwitchRole";
// import PlannerHeader from "../Header/Header";
// import displayPic1 from "../../../../assets/ellipse-191.svg";
import "./PlannerProfileStyles.css";
import PlannerSidebarComponent from "../PlannerSidebar";
import ProfileComponent from "../../ProfileComponent";
import { useMyContext } from "../../../../ContextApi";

export default function PlannerProfile() {
  const { isMobileSidebarOpen, setIsMobileSidebarOpen } = useMyContext();
  const [activeTab, setActiveTab] = useState("profile");
  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);

  const handleClick = (e: React.MouseEvent) => {
    const targetImage = e.target as HTMLImageElement;
    console.log(targetImage);
    if (targetImage.alt === "dashboard-image") return;
    setIsMobileSidebarOpen(false);
  };

  return (
    <div className='vendorpage profile-page' onClick={handleClick}>
      <PlannerSidebarComponent
        isMobileSidebarOpen={isMobileSidebarOpen}
        setIsMobileSidebarOpen={setIsMobileSidebarOpen}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        component='planner'
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />
      {/* <div className="profile-container">
				<PlannerHeader title={"My profile"} hideArrow={true} />
				<div className="profile-main">
					<div className="profile-main-form">
						<div className="profile-main-form-div">
							<div>
								<div>
									<img src={displayPic1} alt="profile picture" />
								</div>
								<div>
									<span>Change photo</span>
									<div>
										<span>5.0</span>
										<div className="star-container">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
											>
												<path
													d="M12.0003 17.27L16.1503 19.78C16.9103 20.24 17.8403 19.56 17.6403 18.7L16.5403 13.98L20.2103 10.8C20.8803 10.22 20.5203 9.12001 19.6403 9.05001L14.8103 8.64001L12.9203 4.18001C12.5803 3.37001 11.4203 3.37001 11.0803 4.18001L9.19032 8.63001L4.36032 9.04001C3.48032 9.11001 3.12032 10.21 3.79032 10.79L7.46032 13.97L6.36032 18.69C6.16032 19.55 7.09032 20.23 7.85032 19.77L12.0003 17.27Z"
													fill="#1C5D99"
												/>
											</svg>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
											>
												<path
													d="M12.0003 17.27L16.1503 19.78C16.9103 20.24 17.8403 19.56 17.6403 18.7L16.5403 13.98L20.2103 10.8C20.8803 10.22 20.5203 9.12001 19.6403 9.05001L14.8103 8.64001L12.9203 4.18001C12.5803 3.37001 11.4203 3.37001 11.0803 4.18001L9.19032 8.63001L4.36032 9.04001C3.48032 9.11001 3.12032 10.21 3.79032 10.79L7.46032 13.97L6.36032 18.69C6.16032 19.55 7.09032 20.23 7.85032 19.77L12.0003 17.27Z"
													fill="#1C5D99"
												/>
											</svg>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
											>
												<path
													d="M12.0003 17.27L16.1503 19.78C16.9103 20.24 17.8403 19.56 17.6403 18.7L16.5403 13.98L20.2103 10.8C20.8803 10.22 20.5203 9.12001 19.6403 9.05001L14.8103 8.64001L12.9203 4.18001C12.5803 3.37001 11.4203 3.37001 11.0803 4.18001L9.19032 8.63001L4.36032 9.04001C3.48032 9.11001 3.12032 10.21 3.79032 10.79L7.46032 13.97L6.36032 18.69C6.16032 19.55 7.09032 20.23 7.85032 19.77L12.0003 17.27Z"
													fill="#1C5D99"
												/>
											</svg>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
											>
												<path
													d="M12.0003 17.27L16.1503 19.78C16.9103 20.24 17.8403 19.56 17.6403 18.7L16.5403 13.98L20.2103 10.8C20.8803 10.22 20.5203 9.12001 19.6403 9.05001L14.8103 8.64001L12.9203 4.18001C12.5803 3.37001 11.4203 3.37001 11.0803 4.18001L9.19032 8.63001L4.36032 9.04001C3.48032 9.11001 3.12032 10.21 3.79032 10.79L7.46032 13.97L6.36032 18.69C6.16032 19.55 7.09032 20.23 7.85032 19.77L12.0003 17.27Z"
													fill="#1C5D99"
												/>
											</svg>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
											>
												<path
													d="M12.0003 17.27L16.1503 19.78C16.9103 20.24 17.8403 19.56 17.6403 18.7L16.5403 13.98L20.2103 10.8C20.8803 10.22 20.5203 9.12001 19.6403 9.05001L14.8103 8.64001L12.9203 4.18001C12.5803 3.37001 11.4203 3.37001 11.0803 4.18001L9.19032 8.63001L4.36032 9.04001C3.48032 9.11001 3.12032 10.21 3.79032 10.79L7.46032 13.97L6.36032 18.69C6.16032 19.55 7.09032 20.23 7.85032 19.77L12.0003 17.27Z"
													fill="#1C5D99"
												/>
											</svg>
										</div>
									</div>
								</div>
							</div>
							<form>
								<div>
									<div>
										<label htmlFor="firstname">First name</label>
										<br />
										<div className="input-container">
											<input type="text" name="firstname" id="firstname" />
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="25"
												height="25"
												viewBox="0 0 25 25"
												fill="none"
											>
												<path
													d="M15.2949 6.49994L18.2949 9.49994M13.2949 20.4999H21.2949M5.29492 16.4999L4.29492 20.4999L8.29492 19.4999L19.8809 7.91394C20.2559 7.53889 20.4665 7.03027 20.4665 6.49994C20.4665 5.96961 20.2559 5.461 19.8809 5.08594L19.7089 4.91394C19.3339 4.539 18.8253 4.32837 18.2949 4.32837C17.7646 4.32837 17.256 4.539 16.8809 4.91394L5.29492 16.4999Z"
													stroke="#5F5F5F"
													stroke-width="2"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										</div>
									</div>
									<div>
										<label htmlFor="lastname">Last name</label>
										<br />
										<div className="input-container">
											<input type="text" name="lastname" id="lastname" />
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="25"
												height="25"
												viewBox="0 0 25 25"
												fill="none"
											>
												<path
													d="M15.2949 6.49994L18.2949 9.49994M13.2949 20.4999H21.2949M5.29492 16.4999L4.29492 20.4999L8.29492 19.4999L19.8809 7.91394C20.2559 7.53889 20.4665 7.03027 20.4665 6.49994C20.4665 5.96961 20.2559 5.461 19.8809 5.08594L19.7089 4.91394C19.3339 4.539 18.8253 4.32837 18.2949 4.32837C17.7646 4.32837 17.256 4.539 16.8809 4.91394L5.29492 16.4999Z"
													stroke="#5F5F5F"
													stroke-width="2"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										</div>
									</div>
								</div>
								<div>
									<div>
										<label htmlFor="email">Email address</label>
										<br />
										<div className="input-container">
											<input type="text" name="email" id="email" />
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="25"
												height="25"
												viewBox="0 0 25 25"
												fill="none"
											>
												<path
													d="M15.2949 6.49994L18.2949 9.49994M13.2949 20.4999H21.2949M5.29492 16.4999L4.29492 20.4999L8.29492 19.4999L19.8809 7.91394C20.2559 7.53889 20.4665 7.03027 20.4665 6.49994C20.4665 5.96961 20.2559 5.461 19.8809 5.08594L19.7089 4.91394C19.3339 4.539 18.8253 4.32837 18.2949 4.32837C17.7646 4.32837 17.256 4.539 16.8809 4.91394L5.29492 16.4999Z"
													stroke="#5F5F5F"
													stroke-width="2"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										</div>
									</div>
									<div>
										<label htmlFor="phonenumber">Phone number</label>
										<br />
										<div className="input-container">
											<input type="text" name="phonenumber" id="phonenumber" />
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="25"
												height="25"
												viewBox="0 0 25 25"
												fill="none"
											>
												<path
													d="M15.2949 6.49994L18.2949 9.49994M13.2949 20.4999H21.2949M5.29492 16.4999L4.29492 20.4999L8.29492 19.4999L19.8809 7.91394C20.2559 7.53889 20.4665 7.03027 20.4665 6.49994C20.4665 5.96961 20.2559 5.461 19.8809 5.08594L19.7089 4.91394C19.3339 4.539 18.8253 4.32837 18.2949 4.32837C17.7646 4.32837 17.256 4.539 16.8809 4.91394L5.29492 16.4999Z"
													stroke="#5F5F5F"
													stroke-width="2"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										</div>
									</div>
								</div>
								<div>
									<div>
										<label htmlFor="category">Job category</label>
										<br />
										<select name="jobs" id="category">
											<option value="">--Please choose an option--</option>
											<option value="job1">Job1</option>
											<option value="job2">Job2</option>
											<option value="job3">Job3</option>
											<option value="job4">Job4</option>
											<option value="job5">Job5</option>
										</select>
									</div>
									<div>
										<label htmlFor="instagram">Instagram handle</label>
										<br />
										<div className="input-container">
											<input type="text" name="instagram" id="instagram" />
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="25"
												height="25"
												viewBox="0 0 25 25"
												fill="none"
											>
												<path
													d="M15.2949 6.49994L18.2949 9.49994M13.2949 20.4999H21.2949M5.29492 16.4999L4.29492 20.4999L8.29492 19.4999L19.8809 7.91394C20.2559 7.53889 20.4665 7.03027 20.4665 6.49994C20.4665 5.96961 20.2559 5.461 19.8809 5.08594L19.7089 4.91394C19.3339 4.539 18.8253 4.32837 18.2949 4.32837C17.7646 4.32837 17.256 4.539 16.8809 4.91394L5.29492 16.4999Z"
													stroke="#5F5F5F"
													stroke-width="2"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										</div>
									</div>
								</div>
								<div>
									<div>
										<label htmlFor="location">Location</label>
										<br />
										<select name="location" id="location">
											<option value="">--Please choose an option--</option>
											<option value="location1">Location1</option>
											<option value="location2">Location2</option>
											<option value="location3">Location3</option>
											<option value="location4">Location4</option>
											<option value="location5">Location5</option>
										</select>
									</div>
								</div>
								<button type="submit">Save changes</button>
							</form>
						</div>
					</div>
				</div>
			</div> */}
      <ProfileComponent />

      {isSwitchUserModalOpen && (
        <SwitchRole
          isSwitchUserModalOpen={isSwitchUserModalOpen}
          setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
          component='planner'
          title='Do you want switch to vendor mode?'
          description=''
          btnText1='Continue as Planner'
          btnText2='Switch to vendor'
        />
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "react-calendar/dist/Calendar.css";
import { AppRoutes } from "../../utils/AppRoutes";
import axios from "axios";
import tableBell from "../../assets/tabler_bell.svg";
import { timeDifference } from "../../utils/reuseableFunctions";
import dayjs from "dayjs";
import CalendarVComponent from "./CalendarComponent/CalendarVComponent";
import HeaderComponent from "./HeaderComponent";
import { useMyContext, fetchData } from "../../ContextApi";
import MainLoader from "./MainLoader";
import { requestFCMToken } from "../../utils/firebase";
import toast from "react-hot-toast";

interface dashboardComponentProps {
  isMobileSidebarOpen: boolean;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  setIsMobileSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  component: string;
  setIsSwitchUserModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function DashboardVComponent({setIsSwitchUserModalOpen }: dashboardComponentProps) {
  const {setWallet,wallet,userInfo, setUserInfo, setFcmToken } = useMyContext();
  const [count, setCount] = useState(0);
  const [fcmRequested, setFcmRequested] = useState(false);
  const navigate = useNavigate();
  const _id = sessionStorage.getItem("_id")
  const [token] = useState(sessionStorage.getItem("token"));
  const [isScreenSmall, setIsScreenSmall] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const { offers, setOffers, upcomingEvents, setUpcomingEvents, dashboardRef } = useMyContext();
  function checkScreenResize() {
    if (window.innerWidth <= 768) {
      setIsScreenSmall(true);
    } else {
      setIsScreenSmall(false);
    }
  }

  const handleClick2 = (id: string) => {
    navigate(AppRoutes.V_ORDER_REQUEST.replace(":offerId", id));
  };

  const getOffers = async (jobCategory: string) => {
    try {
      const response = await axios.get(`https://partypal-api.onrender.com/api/offers/search?category=${jobCategory}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.data.status == 200) {
        setOffers(() => {
          return response.data.offers.filter(({ date }: { date: string }) => {
            const curentTime = dayjs();
            const differenceInDay = dayjs(date).diff(curentTime, "day");
            return differenceInDay >= 0;
          });
        });
      } else {
        setOffers([]);
      }
    } catch (error) {
      setOffers([]);
    }
  };


  const getUpcomingEvents = async () => {
    try {
      const response = await axios.get(`https://partypal-api.onrender.com/api/proposals/vendor`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.data.status === 200) {
        setUpcomingEvents(() => {
          return response.data.proposals
            .filter(({ date, status }: { date: string; status: string }) => {
              const currentTime = dayjs();
              const differenceInDays = dayjs(date).diff(currentTime, "day");
              return differenceInDays >= 0 && status === "accepted";
            })
            .slice(0, 3);
        });
      } else {
        setUpcomingEvents([]);
      }
    } catch (error) {
      setUpcomingEvents([]);
    }
  };
  const requestFCM = async () => {
    try {
      const fcmToken = await requestFCMToken();
      setFcmToken(fcmToken);
      console.log("FCM Token:", fcmToken);
      console.log("User Email:", userInfo.email);

      const userdata = {
        email: userInfo.email,
        fcmToken: fcmToken
      };

      const res = await axios.post("https://partypal-api.onrender.com/api/auth/subscribe", userdata, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });

      if (res.status == 200) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (userInfo?.email && !fcmRequested && Notification.permission === "default") {
      console.log(userInfo.email);
      requestFCM();
      setFcmRequested(true);
    }
  }, [userInfo, fcmRequested]);
  useEffect(() => {
    checkScreenResize();
    window.addEventListener("resize", checkScreenResize);
    return () => {
      window.removeEventListener("resize", checkScreenResize);
    };
  }, []);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const result = await fetchData();
        if (result.status === 200 && result.userData) {
          setUserInfo(result.userData);
          sessionStorage.setItem("userInfo", JSON.stringify(result.userData))
          sessionStorage.setItem("jobCategory", result.userData.jobCategory);
          if (dashboardRef.current === false) {
            getOffers(result.userData.jobCategory);
            getUpcomingEvents();
            dashboardRef.current = true;
          }
        } else {
          console.error("Failed to fetch user data:", result.errorMessage);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchUserData();
  }, [setUserInfo]);

  async function getWallet(){
    const endpoint = `https://partypal-api.onrender.com/api/users/${_id}/wallet/`
    try{
      const response = await axios.get(endpoint,{
        headers:{
          Authorization : `Bearer ${token}`
        }
      })
      if(response.status === 200){
        setWallet({
          ...response.data.wallet
      })
      }
    }catch(error){
      setWallet({
        tokenBalance: 0,
        transactions: [],
        _id: ""
      })
    }

  }


  useEffect(()=>{
    const reference = localStorage.getItem("reference")
    if(!reference){
      getWallet()
    }
  },[])

  return (
    <div className='dashboard-main'>
      {offers.length < 1 && upcomingEvents.length < 1 && !dashboardRef.current && wallet._id === "" ? (
        <div className='flex-1 h-[100vh] flex justify-center items-center'>
          <MainLoader />
        </div>
      ) : (
        <>
          <HeaderComponent nameOfPage="dashboard" pageName={userInfo.firstname ? `Hello, ${userInfo.firstname}` : ""} count={count} setCount={setCount} />
          <div className='dashboard-main-content'>
            <div>
              <div
                className='event'
                onClick={() => {
                  setIsSwitchUserModalOpen(true);
                }}
              >
                <div>
                  <span>Create event</span>
                  <svg xmlns='http://www.w3.org/2000/svg' width='30' height='29' viewBox='0 0 30 29' fill='none'>
                    <path d='M22.5 14.5H7.5M15 7.25V21.75' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                  </svg>
                </div>
                <p>You can create event and plan your event</p>
              </div>
              <div className='token'>
                <div>
                  <svg xmlns='http://www.w3.org/2000/svg' width='32' height='33' viewBox='0 0 32 33' fill='none'>
                    <path
                      d='M32 11.4438C32.0002 13.606 31.3606 15.7198 30.1618 17.519C28.9629 19.3181 27.2585 20.7222 25.2632 21.5542V21.5508C25.2632 17.7534 23.7549 14.1115 21.0702 11.4263C18.3854 8.74113 14.7442 7.23261 10.9474 7.23261H10.944C11.9188 4.89746 13.6721 2.97197 15.9057 1.78338C18.1393 0.594787 20.7156 0.216442 23.1966 0.712642C25.6775 1.20884 27.9102 2.54897 29.5151 4.50527C31.1199 6.46156 31.998 8.91332 32 11.4438ZM7.57895 1.33689C5.79223 1.33689 4.07868 2.04678 2.81528 3.31039C1.55188 4.57401 0.842105 6.28784 0.842105 8.07486V10.6016H4.21053V8.07486C4.21053 7.18135 4.56541 6.32443 5.19711 5.69263C5.82882 5.06082 6.68559 4.70587 7.57895 4.70587H10.1053V1.33689H7.57895ZM27.7895 22.393V24.9198C27.7895 25.8133 27.4346 26.6702 26.8029 27.302C26.1712 27.9338 25.3144 28.2888 24.4211 28.2888H21.8947V31.6578H24.4211C26.2078 31.6578 27.9213 30.9479 29.1847 29.6842C30.4481 28.4206 31.1579 26.7068 31.1579 24.9198V22.393H27.7895ZM10.9474 32.5C13.8508 32.5 16.6353 31.3464 18.6883 29.2931C20.7414 27.2397 21.8947 24.4547 21.8947 21.5508C21.8947 18.6469 20.7414 15.8619 18.6883 13.8085C16.6353 11.7552 13.8508 10.6016 10.9474 10.6016C8.04395 10.6016 5.25944 11.7552 3.20641 13.8085C1.15338 15.8619 0 18.6469 0 21.5508C0 24.4547 1.15338 27.2397 3.20641 29.2931C5.25944 31.3464 8.04395 32.5 10.9474 32.5ZM10.9474 17.3396L15.1579 21.5508L10.9474 25.762L6.73684 21.5508L10.9474 17.3396Z'
                      fill='#1C5D99'
                    />
                  </svg>
                  <p>
                    You have <span>{wallet.tokenBalance}</span> token left in your token wallet
                  </p>
                </div>
                <div>
                  <button
                    onClick={() => {
                      navigate(AppRoutes.V_WALLET);
                    }}
                  >
                    Fund wallet
                  </button>
                  <button
                    onClick={() => {
                      navigate(AppRoutes.V_HISTORY);
                    }}
                  >
                    View history
                  </button>
                </div>
              </div>
              {isScreenSmall && (
                <div className='event-mobile'>
                  <div>
                    <p>Calendar</p>
                    <div className='sub-event'>
                      <svg
                        onClick={() => {
                          navigate(AppRoutes.V_CALENDAR);
                        }}
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='18'
                        viewBox='0 0 16 18'
                        fill='none'
                      >
                        <path
                          d='M15.5 15.6667V3.99999C15.5 3.08082 14.7525 2.33332 13.8333 2.33332H12.1667V0.666656H10.5V2.33332H5.5V0.666656H3.83333V2.33332H2.16667C1.2475 2.33332 0.5 3.08082 0.5 3.99999V15.6667C0.5 16.5858 1.2475 17.3333 2.16667 17.3333H13.8333C14.7525 17.3333 15.5 16.5858 15.5 15.6667ZM5.5 14H3.83333V12.3333H5.5V14ZM5.5 10.6667H3.83333V8.99999H5.5V10.6667ZM8.83333 14H7.16667V12.3333H8.83333V14ZM8.83333 10.6667H7.16667V8.99999H8.83333V10.6667ZM12.1667 14H10.5V12.3333H12.1667V14ZM12.1667 10.6667H10.5V8.99999H12.1667V10.6667ZM13.8333 6.49999H2.16667V4.83332H13.8333V6.49999Z'
                          fill='#1C5D99'
                        />
                      </svg>
                      <p>Upcoming event</p>
                    </div>
                  </div>
                  <div className={upcomingEvents.length > 0 ? "event-nonempty" : "event-empty"}>
                    {upcomingEvents.length > 0 ? (
                      upcomingEvents.map(({ _id, date, venue, description }) => {
                        return (
                          <div key={_id} className='eventday-mobile'>
                            <p>{dayjs(date).format("MMM DD, YYYY")}</p>
                            <div>
                              <p>{dayjs(date).format("h:mm A")}</p>
                              <div></div>
                              <div>
                                <p>{venue}</p>
                                <p>{description}</p>
                              </div>
                              <svg
                                className='svg-icon'
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                viewBox='0 0 18 18'
                                fill='none'
                              >
                                <circle cx='9' cy='9' r='9' fill='#F1F5FA' />
                              </svg>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <>
                        <p>No upcoming event</p>
                        <p>Your calendar would be updated once you accept an event</p>
                      </>
                    )}
                  </div>
                </div>
              )}
              <div className='order-request'>
                <span>Order request</span>
                <NavLink to={AppRoutes.V_ORDER}>View all</NavLink>
              </div>
              <div className={offers.length > 0 ? "table-container filled" : "table-container empty"}>
                {offers.length > 0 ? (
                  <table>
                    <tr>
                      <th>Planner</th>
                      <th>Event date</th>
                      <th>Location</th>
                      <th>Event Title</th>
                    </tr>
                    {offers.slice(0, 4).map(({ _id, date, venue, eventTitle, plannerName }) => {
                      return (
                        <tr key={_id} onClick={() => handleClick2(_id)}>
                          <td>
                            <span>{!plannerName ? "no name" : plannerName}</span>
                          </td>
                          <td>{timeDifference(date)}</td>
                          <td>{venue}</td>
                          <td>{eventTitle}</td>
                        </tr>
                      );
                    })}
                  </table>
                ) : (
                  <div className='no-offer'>
                    <p>No request available</p>
                    <div>
                      <img src={tableBell} alt='notification icon' />
                      <p>We will notify you once there is a request</p>
                    </div>
                  </div>
                )}
              </div>
              <div className='report' onClick={() => navigate(AppRoutes.V_REPORT_EVENT)}>
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
                  <path
                    d='M4.78125 23H3V3.18922L3.21984 2.96984C3.38156 2.80766 4.34906 2 7.5 2C9.24422 2 11.1952 2.68953 12.9164 3.2975C14.3025 3.78734 15.6117 4.25 16.5 4.25C18.5077 4.25 19.9088 3.58203 19.9219 3.575L21 3.05281V15.2136L20.5856 15.4208C20.5177 15.4531 18.8995 16.25 16.5 16.25C15.3684 16.25 13.9509 15.9153 12.4505 15.5605C10.7639 15.162 9.02016 14.75 7.5 14.75C5.77172 14.75 5.16844 15.0116 4.78125 15.177V23Z'
                    fill='#1C5D99'
                  />
                </svg>
                <span>Report event</span>
              </div>
            </div>
            {!isScreenSmall && <CalendarVComponent upcomingEvents={upcomingEvents} />}
          </div>
        </>
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../utils/AppRoutes";
import CalendarSvg from "../../../assets/bxs-calendar.svg";
import Calendar from "react-calendar";
import dayjs from "dayjs";
import "./CalendarStyles.css";
import { jwtDecode, JwtPayload } from "jwt-decode";
import axios from "axios";

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

interface CustomJwtPayload extends JwtPayload {
  user: {
    _id: string;
    jobCategory: string;
    dateRegisteredAsVendor: string;
  };
}

// const baseUrl = process.env.REACT_APP_API_URL;

const CalendarPComponent = () => {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const [value, onChange] = useState<Value>(new Date());
  const [, setIsScreenSmall] = useState(false);
  const [upcomingEvents, setUpcomingEvents] = useState([]);

  const checkScreenResize = () => {
    if (window.innerWidth <= 768) {
      setIsScreenSmall(true);
    } else {
      setIsScreenSmall(false);
    }
  };

  const getUpcomingEvents = async () => {
    if (token) {
      const decoded: CustomJwtPayload = jwtDecode(token);
      const plannerId: string = decoded.user._id;
      try {
        const response = await axios.get(`https://partypal-api.onrender.com/api/events/search?plannerId=${plannerId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        // console.log(response.status)
        if (response.status === 200) {
          setUpcomingEvents(() => {
            return response.data
              .filter(({ datetime }: { datetime: string }) => {
                const currentTime = dayjs();
                const differenceInDays = dayjs(datetime).diff(currentTime, "day");
                return differenceInDays >= 0;
              })
              .slice(0, 3);
          });
        } else {
          setUpcomingEvents([]);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    checkScreenResize();
    window.addEventListener("resize", checkScreenResize);
    return () => {
      window.removeEventListener("resize", checkScreenResize);
    };
  }, []);

  useEffect(() => {
    getUpcomingEvents();
  }, []);

  return (
    <div className='calender-component-1'>
      <img
        onClick={() => navigate(AppRoutes.V_CALENDAR)}
        className='calender-svg hidden sm:flex bg-white p-2 rounded-md'
        src={CalendarSvg}
        alt='calendar svg'
      />
      <p className='hidden sm:flex'>Calendar</p>
      <p className='hidden sm:flex'>Upcoming event</p>
      <div className='h-24 sm:h-60  mb-1 sm:mb-6 overflow-y-auto'>
        {" "}
        {upcomingEvents.length > 0 ? (
          upcomingEvents.map(({ _id, datetime, venue, title }) => {
            return (
              <div key={_id} className='upcoming-event-v'>
                <p>{dayjs(datetime).format("MMM DD, YYYY")}</p>
                <div>
                  <p>{dayjs(datetime).format("h:mm A")}</p>
                  <div></div>
                  <div>
                    <p>{venue}</p>
                    <p>{String(title).length >= 30 ? `${String(title).substring(0, 30)}...` : title}</p>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className='event-empty'>
            <p className='text-[#133E66] font-[600] py-1 text-xl'>No upcoming event</p>
            <p className='text-[#133E66]'>Your calendar would be updated once you accept an event</p>
          </div>
        )}
      </div>
      <div className='hidden sm:flex justify-center'>
        <Calendar
          onChange={onChange}
          onClickDay={(value) => {
            const unixDate = dayjs(value).format("YYYY-MM-DD[T00:00:00.000Z]");
            navigate(`${AppRoutes.V_CALENDAR}/${unixDate}`);
          }}
          value={value}
          tileClassName={({ date }) => {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            if (currentDate > date) {
              return "inactive-day";
            } else {
              return "active-day";
            }
          }}
          className='main-calender'
        />
      </div>
    </div>
  );
};

export default CalendarPComponent;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { JwtPayload } from "jwt-decode";
import toast, { Toaster } from "react-hot-toast";
import { useGoogleLogin } from "@react-oauth/google";
import { Navigate, useNavigate } from "react-router-dom";
import partyPalDark from "../../../assets/Partypal-dark.svg";
import { AppRoutes, PlannerRoutes } from "../../../utils/AppRoutes";
import { useFormik } from "formik";
import * as Yup from "yup";
import Cookies from "js-cookie";
import { Spinner } from "../../common/loader";
import MainLoader from "../../common/MainLoader";

interface CustomJwtPayload extends JwtPayload {
  user: {
    dateRegisterdAsVendor: string;
    jobCategory: string;
    _id: string;
    currentRole: string;
    email: string;
  };
}

export default function LoginComponent() {
  const token = sessionStorage.getItem("token");
  const [forgetPassword, setforgotPassword] = useState(false);
  const validationSchema2 = Yup.object({
    email: Yup.string().email("Invalid email address").required("Email is required"),
    password: Yup.string().required("password is required"),
    rememberMe: Yup.boolean()
  });

  const formik2 = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false
    },
    validationSchema: validationSchema2,
    onSubmit: async (values) => {
      loginApiCall(values);
    }
  });

  const navigate = useNavigate();

  const ENDPOINT = "https://partypal-api.onrender.com/api/auth/signin";
  const [googleLoginLoading, setGoogleLoginLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  function handleClick() {
    navigate(AppRoutes.RESET_PASSWORD);
  }
  function handleClick1() {
    if (count == 0) {
      if (!forgetPassword) {
        navigate("/");
      }
      setforgotPassword(false);
      return;
    }
    setCount((prev) => {
      return prev - 1;
    });
  }

  async function loginApiCall(values: { email: string; password: string; rememberMe: boolean }) {
    setIsLoading(true);
    try {
      const response = await axios.post(ENDPOINT, {
        email: values.email,
        password: values.password
      });
      if (response.status === 200) {
        setIsLoading(false);
        sessionStorage.setItem("token", response.data.token);
        const expiryDate = new Date();
        expiryDate.setHours(expiryDate.getHours() + 1);
        if (values.rememberMe) {
          Cookies.set("token", response.data.token, { expires: expiryDate });
        }
        const decoded: CustomJwtPayload = jwtDecode(response.data.token);
        sessionStorage.setItem("email", decoded.user.email)
        sessionStorage.setItem("_id", decoded.user._id);
        Cookies.set("currentRole", decoded.user.currentRole, { expires: expiryDate });
        if (decoded.user.currentRole == "vendor") {
          navigate(AppRoutes.V_DASH);
        } else {
          navigate(PlannerRoutes.HOME);
        }
      }
    } catch (error: any) {
      setIsLoading(false);
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An Error Occurred");
      }
    }
  }

  function handleKeyDown(e: KeyboardEvent) {
    if (e.key === "Enter") {
      formik2.handleSubmit();
    }
  }

  const singInWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const data = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`
          }
        });
        if (data.status == 200) {
          setGoogleLoginLoading(true);
          const checkForUserRequest = await axios.get(`https://partypal-api.onrender.com/api/users/search?email=${data.data.email}`);
          if (checkForUserRequest.data.no_of_users == 1) {
            try {
              const response = await axios.post(ENDPOINT, {
                email: data.data.email,
                password: `${data.data.family_name}000001`
              });
              if (response.data.status === 200) {
                setGoogleLoginLoading(false);
                sessionStorage.setItem("token", response.data.token);
                const expiryDate = new Date();
                expiryDate.setHours(expiryDate.getHours() + 1);
                Cookies.set("token", response.data.token, { expires: expiryDate });

                const decoded: CustomJwtPayload = jwtDecode(response.data.token);
                sessionStorage.setItem("email", decoded.user.email)
                sessionStorage.setItem("_id", decoded.user._id);
                Cookies.set("currentRole", decoded.user.currentRole, { expires: expiryDate });
                if (decoded.user.currentRole == "vendor") {
                  navigate(AppRoutes.V_DASH);
                } else {
                  navigate(PlannerRoutes.HOME);
                }
              } else {
                setGoogleLoginLoading(false);
                toast.error(response.data.message);
              }
            } catch (error: any) {
              setGoogleLoginLoading(true);
              if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
              } else if (error.message) {
                toast.error(error.message);
              } else {
                toast.error("An Error Occurred");
              }
            }
          } else {
            navigate(AppRoutes.SIGNUP);
          }
        }
      } catch (err) {
        toast.error("An Error Occurred");
      }
      // You can now use the tokenResponse to authenticate the user in your app
    },
    onError: () => {
      console.error("Google login failed");
      // Handle login errors here
    }
  });

  if (token) {
    const decoded: CustomJwtPayload = jwtDecode(token);
    if (decoded.user.dateRegisterdAsVendor) {
      Cookies.set("jobcategory", decoded.user.jobCategory);
      Cookies.set("_id", decoded.user._id);
      return <Navigate to={AppRoutes.V_DASH} />;
    } else {
      Cookies.set("jobcategory", decoded.user.jobCategory);
      Cookies.set("_id", decoded.user._id);
      return <Navigate to={PlannerRoutes.HOME} />;
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return (
    <>
      {!googleLoginLoading ? (
        <div className='loginpage'>
          <Toaster />
          <svg
            onClick={handleClick1}
            className='loginpage-svg'
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
          >
            <path
              d='M16.62 2.98979C16.5039 2.87338 16.366 2.78103 16.2141 2.71801C16.0622 2.655 15.8994 2.62256 15.735 2.62256C15.5706 2.62256 15.4078 2.655 15.2559 2.71801C15.1041 2.78103 14.9661 2.87338 14.85 2.98979L6.54 11.2998C6.4473 11.3923 6.37375 11.5022 6.32357 11.6232C6.27339 11.7441 6.24756 11.8738 6.24756 12.0048C6.24756 12.1358 6.27339 12.2654 6.32357 12.3864C6.37375 12.5074 6.4473 12.6173 6.54 12.7098L14.85 21.0198C15.34 21.5098 16.13 21.5098 16.62 21.0198C17.11 20.5298 17.11 19.7398 16.62 19.2498L9.38 11.9998L16.63 4.74979C17.11 4.26979 17.11 3.46979 16.62 2.98979Z'
              fill='#262626'
            />
          </svg>
          <div className='loginpage-main'>
            {/* <h1 className='loginpage-heading'>Partypal</h1> */}
            <img src={partyPalDark} alt='logo' />
            <div className='loginpage-content'>
              <p className='login-title'>Log in</p>
              <form className='login-form' onSubmit={formik2.handleSubmit}>
                <input
                  type='email'
                  name='email'
                  placeholder='E-mail address'
                  onChange={formik2.handleChange}
                  onBlur={formik2.handleBlur}
                  value={formik2.values.email}
                  className={formik2.touched.email && formik2.errors.email ? "invalid" : "correct"}
                />
                <br />
                <div className='login-password'>
                  <input
                    type={!isPasswordVisible ? "password" : "text"}
                    name='password'
                    placeholder='Password'
                    onChange={formik2.handleChange}
                    onBlur={formik2.handleBlur}
                    value={formik2.values.password}
                    className={formik2.touched.password && formik2.errors.password ? "invalid" : "correct"}
                  />
                  <div
                    className='icon-container'
                    onClick={() => {
                      setIsPasswordVisible((prev) => !prev);
                    }}
                  >
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                      <path
                        d='M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z'
                        fill='#5F5F5F'
                      />
                      <path
                        d='M18.5072 6.61781C16.4578 5.2125 14.2645 4.5 11.9887 4.5C9.94078 4.5 7.94438 5.10937 6.05484 6.30375C4.14937 7.51078 2.28141 9.70312 0.75 12C1.98844 14.0625 3.6825 16.1831 5.44687 17.3991C7.47094 18.7931 9.67172 19.5 11.9887 19.5C14.2856 19.5 16.4817 18.7936 18.5184 17.4005C20.3114 16.1719 22.0177 14.0541 23.25 12C22.0134 9.96422 20.3016 7.84875 18.5072 6.61781ZM12 16.5C11.11 16.5 10.24 16.2361 9.49993 15.7416C8.75991 15.2471 8.18314 14.5443 7.84254 13.7221C7.50195 12.8998 7.41283 11.995 7.58647 11.1221C7.7601 10.2492 8.18868 9.44736 8.81802 8.81802C9.44736 8.18868 10.2492 7.7601 11.1221 7.58647C11.995 7.41283 12.8998 7.50195 13.7221 7.84254C14.5443 8.18314 15.2471 8.75991 15.7416 9.49993C16.2361 10.24 16.5 11.11 16.5 12C16.4986 13.1931 16.0241 14.3369 15.1805 15.1805C14.3369 16.0241 13.1931 16.4986 12 16.5Z'
                        fill='#5F5F5F'
                      />
                    </svg>
                    {!isPasswordVisible && <span className='line'></span>}
                  </div>
                </div>
                <div className='reset-password'>
                  <div>
                    <input
                      type='checkbox'
                      name='rememberMe'
                      onChange={formik2.handleChange}
                      onBlur={formik2.handleBlur}
                      checked={formik2.values.rememberMe}
                    />
                    <span>Remember me</span>
                  </div>
                  <span onClick={handleClick}>Forgot password?</span>
                </div>
                {/* disabled={isButtonDisabled} */}
                <button type='submit' className='login-btn'>
                  {isLoading ? (
                    <>
                      <span className='text-xl font-normal'>Logging in...</span>&nbsp;
                      <Spinner />
                    </>
                  ) : (
                    "Log in"
                  )}
                </button>
              </form>
              <div className='divider'>
                <span></span>
                <span>or</span>
                <span></span>
              </div>
              <div onClick={() => singInWithGoogle()} className='login-with-google'>
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                  <path
                    d='M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z'
                    fill='#FFC107'
                  />
                  <path
                    d='M3.15283 7.3455L6.43833 9.755C7.32733 7.554 9.48033 6 11.9998 6C13.5293 6 14.9208 6.577 15.9803 7.5195L18.8088 4.691C17.0228 3.0265 14.6338 2 11.9998 2C8.15883 2 4.82783 4.1685 3.15283 7.3455Z'
                    fill='#FF3D00'
                  />
                  <path
                    d='M12.0002 21.9999C14.5832 21.9999 16.9302 21.0114 18.7047 19.4039L15.6097 16.7849C14.5719 17.574 13.3039 18.0009 12.0002 17.9999C9.39916 17.9999 7.19066 16.3414 6.35866 14.0269L3.09766 16.5394C4.75266 19.7779 8.11366 21.9999 12.0002 21.9999Z'
                    fill='#4CAF50'
                  />
                  <path
                    d='M21.8055 10.0415H21V10H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z'
                    fill='#1976D2'
                  />
                </svg>
                <span>Log in with google</span>
              </div>
              <div className='login-with-apple'>
                <svg xmlns='http://www.w3.org/2000/svg' width='21' height='24' viewBox='0 0 21 24' fill='none'>
                  <path
                    d='M16.9554 23.0371C15.6406 24.3036 14.2049 24.1036 12.8229 23.5037C11.3605 22.8904 10.0187 22.8638 8.47576 23.5037C6.54368 24.3303 5.52397 24.0903 4.37009 23.0371C-2.17751 16.3313 -1.21147 6.11923 6.22167 5.74594C8.03299 5.83926 9.29421 6.73248 10.3542 6.81247C11.9374 6.49251 13.4535 5.57263 15.1441 5.69261C17.1701 5.85259 18.6997 6.65249 19.706 8.09231C15.5198 10.5853 16.5127 16.0646 20.35 17.5978C19.5852 19.5975 18.5923 21.5839 16.942 23.0504L16.9554 23.0371ZM10.22 5.66595C10.0187 2.69299 12.4473 0.23997 15.238 0C15.6271 3.43957 12.0984 5.99924 10.22 5.66595Z'
                    fill='#262626'
                  />
                </svg>
                <span>Log in with apple</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='h-[100vh] w-[100%] flex justify-center items-center'>
          <MainLoader />
        </div>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
//import { useForm, SubmitHandler } from "react-hook-form";
import "./CreateEventStyles.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

interface CreateEventProps {
  openSuccessModal: () => void;
  closeCreateEventModal: () => void;
  setEventId: React.Dispatch<React.SetStateAction<string>>;
}

const endpoint = "https://partypal-api.onrender.com/api/events";

const CreateEvent: React.FC<CreateEventProps> = ({ openSuccessModal, closeCreateEventModal, setEventId }) => {
  const [value, setValue] = React.useState<Dayjs | null>(dayjs);
  const [token] = useState(sessionStorage.getItem("token"));
  const [isLoading, setIsLoading] = useState(false);
  const [isDateInValid, setIsDateInValid] = useState(false);
  const validationSchema = Yup.object({
    title: Yup.string().required("title is required"),
    venue: Yup.string().required("venue is required")
  });
  const [isScreenSmall, setIsScreenSmall] = useState(false);
  console.log(isDateInValid);
  const formik = useFormik({
    initialValues: {
      title: "",
      venue: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // Handle form submission
      if (isDateInValid || value == null) return;
      setIsLoading(true);
      try {
        const response = await axios.post(
          endpoint,
          {
            ...values,
            datetime: dayjs(value).format("YYYY/MM/DD")
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (response.status === 200) {
          setIsLoading(false);
          setEventId(response.data.event._id);
          resetForm();
          openSuccessModal();
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }

      // Reset the form after successful submission
    }
  });

  function checkScreenResize() {
    if (window.innerWidth <= 768) {
      setIsScreenSmall(true);
    } else {
      setIsScreenSmall(false);
    }
  }

  useEffect(() => {
    checkScreenResize();
    window.addEventListener("resize", checkScreenResize);
    return () => {
      window.removeEventListener("resize", checkScreenResize);
    };
  }, []);

  return (
    <>
      <div className='planner-modal'>
        <div className='create-event-heading'>
          <h1>Create Event</h1>
          <p>You can now create event and find vendors</p>
        </div>
        <form onSubmit={formik.handleSubmit} className='create-event-form'>
          <div className='event-form-input'>
            <label htmlFor='event-title'>Event title</label>
            <input
              type='text'
              placeholder='e.g my mom’s 50th birthday'
              name='title'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              maxLength={50}
            />
            {formik.touched.title && formik.errors.title ? <p className='error'>{formik.errors.title}</p> : null}
          </div>
          <div className='event-form-input'>
            <label htmlFor='event-date'>Date of event</label>
            {!isScreenSmall ? (
              <DatePicker
                className='datepicker'
                defaultValue={dayjs()}
                value={value}
                onChange={(newValue) => {
                  if (newValue) {
                    if (dayjs() > newValue) {
                      setIsDateInValid(true);
                    } else {
                      setIsDateInValid(false);
                    }
                    setValue(newValue);
                  }
                }}
              />
            ) : (
              <MobileDatePicker
                className='datepicker 2'
                defaultValue={dayjs()}
                value={value}
                onChange={(newValue) => {
                  if (newValue) {
                    if (dayjs() > newValue) {
                      setIsDateInValid(true);
                    } else {
                      setIsDateInValid(false);
                    }
                    setValue(newValue);
                  }
                }}
              />
            )}
            {isDateInValid ? <p className='error'>Past event can&apos;t be created</p> : null}
          </div>
          <div className='event-form-input'>
            <label htmlFor='event-location'>Location</label>
            <input
              type='text'
              placeholder='event address'
              name='venue'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.venue}
            />
            {formik.touched.venue && formik.errors.venue ? <p className='error'>{formik.errors.venue}</p> : null}
          </div>
          <button className='modal-continue-btn' type='submit'>
            {isLoading ? "Creating..." : "Continue"}
            {!isLoading && (
              <svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'>
                <path
                  d='M11.5577 3.05737C11.8077 2.80741 12.1468 2.66699 12.5003 2.66699C12.8539 2.66699 13.193 2.80741 13.443 3.05737L19.443 9.05737C19.693 9.30741 19.8334 9.64649 19.8334 10C19.8334 10.3536 19.693 10.6927 19.443 10.9427L13.443 16.9427C13.1915 17.1856 12.8547 17.32 12.5051 17.3169C12.1555 17.3139 11.8211 17.1737 11.5739 16.9265C11.3267 16.6793 11.1865 16.3448 11.1834 15.9952C11.1804 15.6456 11.3148 15.3088 11.5577 15.0574L15.167 11.3334H2.50033C2.1467 11.3334 1.80757 11.1929 1.55752 10.9429C1.30747 10.6928 1.16699 10.3537 1.16699 10C1.16699 9.64642 1.30747 9.30728 1.55752 9.05723C1.80757 8.80718 2.1467 8.66671 2.50033 8.66671H15.167L11.5577 4.94271C11.3077 4.69267 11.1673 4.35359 11.1673 4.00004C11.1673 3.64649 11.3077 3.30741 11.5577 3.05737Z'
                  fill='white'
                />
              </svg>
            )}
          </button>
        </form>
        <div className='close-modal' onClick={closeCreateEventModal}>
          <img src='/close-icon.svg' alt='close-icon' />
        </div>
      </div>
      <div onClick={closeCreateEventModal} className='planner-modal-overlay'></div>
    </>
  );
};

export default CreateEvent;
